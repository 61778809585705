import React from 'react'

function Exprincevd() {
  return (
<>
<div class="tp-video-area bg-position jarallax p-relative">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="tp-video-three-wrap tp-video-three-spacing text-center">
                     <div class="tp-video-main mb-45">
                        <a class="tp-video-three-play popup-video tp-pulse-border p-relative" href="https://www.youtube.com/watch?v=CcPMz5u-OQI">
                           <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M23 13L0.5 25.9904V0.00961876L23 13Z" fill="#FAF5EF" />
                           </svg>
                        </a>
                     </div>
                     <h3 class="tp-video-title">Experience a <a href="#">Memorable</a><br/> Stay at our Hotel.!</h3>
                  </div>
               </div>
            </div>
         </div>
         <div class="tp-video-great-work">
            <div class="container">
               <div class="row">
                  <div class="col-12">
                     <div class="tp-video-great-content text-center">
                        <p>Just 50 meters from Mahakaleshwar Mandir 
                           {/* <a href="#">Got a project in mind?
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M1 11L11 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                 <path d="M1 1H11V11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                           </a> */}
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         </div>
</>
  )
}

export default Exprincevd
import React from 'react';
import image1 from './jainnamkin/horizontal png jain.png'

const Herosec = () => {
  return (
    <div className="ck_slider_main_wrapper float_left" style={{marginTop:'120px'}}>
      <div className="container">
        <div className="row row-top-img " >
          {/* Left-side image column */}
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 align-self-center">
            <div className="ck_main_slider_img float_left">
              <img
                style={{ height: "auto",
                  width:"110%"}}
                src={image1}
                alt="img"
              />
            </div>
          </div>

          {/* Right-side content column */}
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 align-self-center">
            <div className="ck_slider_top_content float_left">
              <h2>Freshmade Goodness!</h2>
              <h3>
              Taste the Authentic
                <br />
                Ujjainye Namkeen
              </h3>
              <ul>
                <li>
                  <a href="#">read more</a>
                </li>
                <li>
                  <a href="#">shop now</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Herosec;

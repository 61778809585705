import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert2';
import { Card, Divider, Tag, Button, Spin } from 'antd';

function Myroombooking() {
  const user = JSON.parse(localStorage.getItem('user'));
  const [booking, setBooking] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          'https://jainshree-nakin-hotel-1.onrender.com/api/rooms/getroombyuserid',
          { userid: user._id }
        );
        
        console.log(response.data, 'userRoom');
        setBooking(response.data); // Assuming response.data is the array you want
        setLoading(false);
      } catch (error) {
        console.error('Error fetching room data:', error);
        setLoading(false);
      }
    };
    
    fetchRoomDetails();
  }, []);

  async function cancelBooking(bookingId, roomId) {
    try {
      setLoading(true);
      const result = await axios.post('https://jainshree-nakin-hotel-1.onrender.com/api/rooms/cancellroom', { bookingid: bookingId, roomid: roomId });
      console.log(result);

      // Update the booking status to "canceled" in the local state
      setBooking(prevBookings =>
        prevBookings.map(b =>
          b._id === bookingId ? { ...b, status: 'cancelled' } : b
        )
      );
      swal.fire('Your booking has been cancelled successfully').then(() => {
        window.location.reload();
      });
      setLoading(false);
    } catch (error) {
      console.error('Error canceling booking:', error);
      setLoading(false);
      swal.fire('Error canceling booking');
    }
  }

  return (
    <>
      {loading && <Spin className="spinner" tip="Loading..." />}
      <div style={{ padding: '20px' }}>
        <h1>My Bookings</h1>
        <Divider />
        {Array.isArray(booking) && booking.length > 0 ? (
          booking.map((bookingItem, index) => (
            <Card
              key={index}
              style={{
                marginBottom: '20px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              }}
              title={<h2>Room: {bookingItem.room}</h2>}
              extra={
                <Tag color={bookingItem.status === 'cancelled' ? 'red' : 'green'}>
                  {bookingItem.status === 'cancelled' ? 'CANCELLED' : 'CONFIRMED'}
                </Tag>
              }
            >
              <p><strong>Booking ID:</strong> {bookingItem._id}</p>
              <p><strong>Room ID:</strong> {bookingItem.roomid}</p>
              <p><strong>Arrival Date:</strong> {bookingItem.arrivalDate}</p>
              <p><strong>Departure Date:</strong> {bookingItem.departureDate}</p>
              <p><strong>Status:</strong> {bookingItem.status === 'cancelled' ? 'Cancelled' : 'Confirmed'}</p>
              {bookingItem.status === 'booked' && (
                <Button type="primary" danger onClick={() => cancelBooking(bookingItem._id, bookingItem.roomid)}>
                  Cancel Room
                </Button>
              )}
            </Card>
          ))
        ) : (
          <p>No bookings found</p>
        )}
      </div>
    </>
  );
}

export default Myroombooking;

import React from 'react';


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from '../jainnamkin/sample white.png'
import namkeenPare from '../jainnamkin/jain shree products edited/namkeen pare.png';
import daalMoth from '../jainnamkin/jain shree products edited/daal moth.png';
import sevSpicy from '../jainnamkin/jain shree products edited/sev- spicy.png';

const _items = [
  {
    title: 'Namkeen Pare',
    desc: 'TRY NOW',
    image: namkeenPare, // Replace with actual image path
  },
  {
    title: 'Daal Moth',
    desc: 'TRY NOW',
    image: daalMoth, // Replace with actual image path
  },
  {
    title: 'Sev Spicy',
    desc: 'TRY NOW',
    image: sevSpicy, // Replace with actual image path
  },
];

function Carousel() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
      };
  return (
    <div>
   

  <div className="owl-carousel owl-theme">
    
    {/* Slide 1 */}
    {/* <Slider {...settings}> */}
{_items.map((item)=>(

<div className="item">
<div className="btc_team_slider_cont_main_wrapper">
  <div className="btc_team_img_wrapper">
    <img
      src={item.image}
      alt="Pecan Cookies"
    />
    <div className="btc_team_social_wrapper">
      <h5>TRY NOW</h5>
    </div>
  </div>
  <div className="btc_team_img_cont_wrapper">
    <h4><a href="#">{item.title}</a></h4>
  </div>
</div>
</div>
))}
  {/* </Slider> */}
    {/* <div className="item">
      <div className="btc_team_slider_cont_main_wrapper">
        <div className="btc_team_img_wrapper">
          <img
            src={image2}
            alt="Dream Cookies"
          />
          <div className="btc_team_social_wrapper">
            <h5>40% OFF</h5>
          </div>
        </div>
        <div className="btc_team_img_cont_wrapper">
          <h4><a href="#">Dream Cookies</a></h4>
        </div>
      </div>
    </div>


    <div className="item">
      <div className="btc_team_slider_cont_main_wrapper">
        <div className="btc_team_img_wrapper">
          <img
            src={image3}
            alt="Philippinest"
          />
          <div className="btc_team_social_wrapper">
            <h5>40% OFF</h5>
          </div>
        </div>
        <div className="btc_team_img_cont_wrapper">
          <h4><a href="#">Philippinest</a></h4>
        </div>
      </div>
    </div> */}

  </div>

</div>
 
  );
}

export default Carousel;

import React, { useContext } from 'react';
import { DatePicker } from 'antd';
import { DataContext } from '../HOOKS/Context';
import moment from 'moment';

const BookingSection = () => {
  const { setDepartureDate, setArrivalDate, departureDate, arrivalDate } = useContext(DataContext);
  
  const handleArrivalDateChange = (date) => {
    if (date) {
      const formattedDate = date.format("DD-MM-YYYY"); // Format as dd-mm-yyyy
      setArrivalDate(formattedDate);
    }
  };

  const handleDepartureDateChange = (date) => {
    if (date) {
      const formattedDate = date.format("DD-MM-YYYY");
      setDepartureDate(formattedDate);
    }
  };
  const disabledDate = (current) => {
    const today = moment().startOf("day"); // Start of today
    const threeMonthsFromNow = moment().add(3, "months").endOf("day"); // End of three months from now
    return current && (current.isBefore(today) || current.isAfter(threeMonthsFromNow));
  };
  return (
    <div id="booking-2" className="tp-booking-area tp-falured-bg pt-50 pb-60">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="tp-booking-title-wrap mb-45 text-center p-relative">
              <h2 className="tp-booking-title">Reservations</h2>
              <span className="tp-booking-border p-absolute"></span>
            </div>
          </div>

          <div className="col-12">
            <form action="#">
              <div style={{alignItems:'center', justifyContent:'center'}} className="row">
                {/* Arrival Date */}
                <div className="col-lg-3 col-md-6">
                  <div className="tp-booking-form-input tp-booking-active pb-20 ">
                    <p>Arrival Date</p>
                    <DatePicker
                      value={arrivalDate ? moment(arrivalDate, "DD-MM-YYYY") : null}
                      format="DD-MM-YYYY"
                      onChange={handleArrivalDateChange}
                      style={{ width: "100%" }}
                      placeholder="Select arrival date"
                      disabledDate={disabledDate}
                    />
                  </div>
                </div>

                {/* Departure Date */}
                <div className="col-lg-3 col-md-6">
                  <div className="tp-booking-form-input pb-20 ">
                    <p>Departure Date</p>
                    <DatePicker
                      value={departureDate ? moment(departureDate, "DD-MM-YYYY") : null}
                      format="DD-MM-YYYY"
                      onChange={handleDepartureDateChange}
                      style={{ width: "100%" }}
                      placeholder="Select departure date"
                      disabledDate={disabledDate}
                    />
                  </div>
                </div>

                {/* Submit Button */}
                <div className="col-lg-3 col-md-6">
                  <div className="tp-booking-btn-wrap pb-20 pt-25">
                    <a id="booking-4" className="tp-booking-form-btn" type="button"  href="#booking-3">
                    Find Rooms
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSection;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Divider, Card, Row, Col } from 'antd';
import Mybooking from './Myroombooking';

const { TabPane } = Tabs;

function Profile() {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login'); // Redirect to login or desired path if user is not logged in
    }
  }, [user, navigate]);

  return (
    <div style={{
      marginTop: '100px', 
      padding: '20px', 
      maxWidth: '800px', 
      margin: '100px auto',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      backgroundColor: '#fff'
    }}>
      <h1>Profile</h1>
      <Divider />
      <Tabs defaultActiveKey="1">
        <TabPane tab="Profile" key="1">
          <Card title="My Profile" bordered={false} style={{ marginBottom: '20px' }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <p><strong>Name:</strong> {user.name}</p>
              </Col>
              <Col span={12}>
                <p><strong>Email:</strong> {user.email}</p>
              </Col>
            </Row>
          </Card>
        </TabPane>
        
        <TabPane tab="My Bookings" key="2">
          <Card title="My Bookings" bordered={false}>
            <Mybooking />
          </Card>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Profile;

import React from 'react'

import logo from '../JAINSHREE/jainnamkin/web logo jain shree.png'
import { FaInstagram } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { Link } from 'react-router-dom';
function Footer() {
  return (
  <>
  
  
      <footer id="booking-3">
         <div class="tp-footer-area tp-falured-bg-2 pt-120">
         <div class="footer-py-60 footer-border">
                                <div class="row">
                                    <div class="col-lg-5 col-8 mb-0 mb-md-4 pb-0 pb-md-2 d-flex flex-column nakin-footer-div-logo  " style={{ width: " 350px" }}>
                                        <a href="#" class="logo-footer">
                                            <img height="100px" src={logo} class="logo-light-mode namkin-footer-logo" alt="" />
                                        </a>
                                        <p class="mt-4 footter-text-p" >Jain Sree Group's hotel in Ujjain offers the perfect blend of comfort and convenience for travelers visiting the holy city. With well-furnished rooms, warm hospitality, and modern amenities, 
                                        </p>
                                        <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                            <li class="list-inline-item"><a href="" target="_blank" class="rounded-3"><i data-feather="shopping-cart" class="fea icon-sm align-middle" title="Buy Now"></i></a></li>

                                            <FaInstagram color='white' />
                                           <FaFacebook color='white' style={{marginLeft:'20px'}}/>
                                           <MdOutlineMail color='white'style={{marginLeft:'20px'}}/>
                                          <FaTwitter color='white' style={{marginLeft:'20px'}}/>
                                        </ul>
                                    </div>

                                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <h5 class="footer-head">Company</h5>
                                        <ul class="list-unstyled footer-list mt-4">
                                        <Link to='/gallerySection'>      <li><i class="mdi mdi-chevron-right align-middle me-1"></i> Gallery</li> </Link>

<Link to='/Contect'>       <li><i class="mdi mdi-chevron-right align-middle me-1"></i> Contect</li>  </Link>  

  <Link to='/Namkeen'>       <li><i class="mdi mdi-chevron-right align-middle me-1"></i> Namkeen </li>  </Link>
                                        </ul>
                                    </div>


                                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <h5 class="footer-head">Contact Details</h5>

                                        {/* <div class="d-flex mt-4">
                                            <i data-feather="map-pin" class="fea icon-sm text-primary mt-1 me-3"></i>
                                            <div class="cont-det">
                                                <p class="mb-2">melbourn</p>

                                            </div>
                                        </div>

                                        <div class="d-flex mt-4">
                                            <i data-feather="mail" class="fea icon-sm text-primary mt-1 me-3"></i>
                                            <a href="mailto:contact@example.com" class="text-foot">enquiries@suriandco.com.au</a>
                                        </div>

                                        <div class="d-flex mt-4">
                                            <i data-feather="phone" class="fea icon-sm text-primary mt-1 me-3"></i>
                                            <a href="tel:+152534-468-854" class="text-foot">+61 424877421</a>

                                        </div> */}
                                            <ul class="list-unstyled footer-list mt-4">
                                        <Link to='/Products'>         <li><i class="mdi mdi-chevron-right align-middle me-1"></i> Ujjain</li> </Link> 


                                      <Link to='/Products'>      <li>  jainshreehotel@gmail.com</li> </Link>

                                   <Link to='/SweetsCategory'>       <li><i class="mdi mdi-chevron-right align-middle me-1"></i> +91 9111496265</li>  </Link>  

                                   
                                        </ul>
                                    </div>

                                </div>
                            </div>
            <div class="tp-footer-botom">
            <div class="container">
                  <div class="tp-footer-bottom-inner">
                     <div class="row">
                        <div class="col-sm-6">
                           <div class="tp-footer-copyright tp-footer-copyright-3">
                              <p style={{color:'white'}}>Copyright © 2024 <a href="#" style={{color:'white'}}>Jainshree</a>. All Rights Reserved.</p>
                           </div>
                        </div>
                        <div class="col-sm-6">
                           <div class="tp-footer-develop tp-footer-copyright tp-footer-copyright-3 text-right">
                              <p style={{color:'white'}}>Developed by: <a href="#" style={{color:'white'}}>MEDIA MANSION</a></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
  </>
  )
}

export default Footer
import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: 'cart',
    initialState: [],
    reducers: {
        add: (state, action) => {
            const newItem = { ...action.payload, quantity: 1 }; 
            state.push(newItem);
        },
        remove: (state, action) => {
            return state.filter(item => item.id !== action.payload);
        },
        updateQuantity: (state, action) => {
            const { id, quantity } = action.payload;
            const item = state.find(item => item.id === id);
            if (item) {
                item.quantity = quantity;
            }
        },
    },
});

export default cartSlice.reducer;
export const { add, remove, updateQuantity } = cartSlice.actions;

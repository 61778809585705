import React from 'react';

function RoomCard({ room , arrivalDate , departureDate }) {
  
  return (
    <div className="tp-room-wrap">
      <div className="tp-room-thumb mb-35">
        <img className="roomcard" src={room.imageurls || 'default-image.jpg'} alt={room.name} />
      </div>
      <div className="tp-room-content">
        <span className="tp-room-tag mb-15">{room.type}</span>
        <h4 className="tp-room-title mb-20">
          <a href={`/RoomDetails/${room._id}`}>{room.name}</a>
        </h4>
        <div className="tp-room-meta mb-30">
          <ul>

            <li><span>Adults: {room.adults}</span></li>
            <li><span>{room.breakfast ? 'Breakfast included' : 'No breakfast'}</span></li>
            <li><span>Size: {room.size}ft²</span></li>
          </ul>
        </div>
       
  <div className="tp-room-btn-2">
  <a className="tp-btn-4" href={`/RoomDetails/${room._id}/${arrivalDate}/${departureDate}`}>Book</a>
</div>
     
      
      </div>
    </div>
  );
}

export default RoomCard;

import { createContext ,useState } from "react";

export const DataContext = createContext()

export const Dataprovider = ({children}) =>{
    const [arrivalDate, setArrivalDate] = useState('');
    const [departureDate, setDepartureDate] = useState('');
    const namedata  = "data is here "
    console.log(arrivalDate,departureDate,'context data');
    
    return <DataContext.Provider value={{setArrivalDate,arrivalDate,departureDate,setDepartureDate,}}>
 {children}
    </DataContext.Provider>
}


import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { add } from '../redux/CartSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import product images
import boondiSpicy from './jainnamkin/jain shree products edited/boondi-spicy.png';
import chakli from './jainnamkin/jain shree products edited/chakli.png';
import chanaDaalSpicy from './jainnamkin/jain shree products edited/chana daal- spicy.png';
import chanaDaalPudhina from './jainnamkin/jain shree products edited/chana daal- pudina flavor.png';
import cheewda from './jainnamkin/jain shree products edited/cheewda.png';
import chipsBlackPepper from './jainnamkin/jain shree products edited/chips- black pepper.png';
import daalMoth from './jainnamkin/jain shree products edited/daal moth.png';
import danthalSev from './jainnamkin/jain shree products edited/danthal sev.png';
import fariyaliMixtureSpicy from './jainnamkin/jain shree products edited/fariyali mixture- spicy (2).png';
import fariyaliMixtureSweet from './jainnamkin/jain shree products edited/fariyali mixture- sweet.png';
import feekiSev from './jainnamkin/jain shree products edited/feeki sev.png';
import gathiye from './jainnamkin/jain shree products edited/gathiye.png';
import khattaMeethaMixture from './jainnamkin/jain shree products edited/khatta-meetha mixture.png';
import laalSev from './jainnamkin/jain shree products edited/laal sev.png';
import matree from './jainnamkin/jain shree products edited/matree.png';
import miniSamosa from './jainnamkin/jain shree products edited/mini samosa.png';
import mixtureMild from './jainnamkin/jain shree products edited/mixture- mild.png';
import mixtureSpicy from './jainnamkin/jain shree products edited/mixture- spicy.png';
import mixture from './jainnamkin/jain shree products edited/mixture.png';
import moongDaal from './jainnamkin/jain shree products edited/moong daal.png';
import moongfaliDaneSpicy from './jainnamkin/jain shree products edited/moongfali dane- spicy.png';
import motiDanthal from './jainnamkin/jain shree products edited/moti danthal.png';
import mungfaliDaaneKaliMirch from './jainnamkin/jain shree products edited/mungfali daane- kali mirch.png';
import ujjainiSev from './jainnamkin/jain shree products edited/ujjaini  sev.png';
import tastyNuts from './jainnamkin/jain shree products edited/tasty nuts.png';
import sweetChips from './jainnamkin/jain shree products edited/sweet chips.png';
import spicyChips from './jainnamkin/jain shree products edited/spicy chips.png';
import shakkarPare from './jainnamkin/jain shree products edited/shakkar pare.png';
import sevSpicy from './jainnamkin/jain shree products edited/sev- spicy.png';
import saltedChips from './jainnamkin/jain shree products edited/salted chips.png';
import sabudanaMixture from './jainnamkin/jain shree products edited/sabudana mixture.png';
import namkeenPapdi from './jainnamkin/jain shree products edited/namkeen papdi.png';
import namkeenPare from './jainnamkin/jain shree products edited/namkeen pare.png';
import palakSev from './jainnamkin/jain shree products edited/palak sev.png';
import patliDanthal from './jainnamkin/jain shree products edited/patli danthal.png';
import ratlamiSev from './jainnamkin/jain shree products edited/ratlami sev.png';
import sabudanaMixtureSpicy from './jainnamkin/jain shree products edited/sabudana mixture- spicy.png';
// ... (other imports here)

const productsData = [
  { id: 1, name: 'Boondi Spicy', price: '80', image: boondiSpicy },
  { id: 2, name: 'Chakli', price: '120', image: chakli },
  { id: 3, name: 'Chana Daal Spicy', price: '100', image: chanaDaalSpicy },
  { id: 4, name: 'Chana Daal Pudina Flavor', price: '110', image: chanaDaalPudhina },
  { id: 5, name: 'Cheewda', price: '90', image: cheewda },
  { id: 6, name: 'Chips Black Pepper', price: '85', image: chipsBlackPepper },
  { id: 7, name: 'Daal Moth', price: '95', image: daalMoth },
  { id: 8, name: 'Danthal Sev', price: '70', image: danthalSev },
  { id: 9, name: 'Fariyali Mixture Spicy', price: '120', image: fariyaliMixtureSpicy },
  { id: 10, name: 'Fariyali Mixture Sweet', price: '115', image: fariyaliMixtureSweet },
  { id: 11, name: 'Feeki Sev', price: '100', image: feekiSev },
  { id: 12, name: 'Gathiye', price: '75', image: gathiye },
  { id: 13, name: 'Khatta Meetha Mixture', price: '105', image: khattaMeethaMixture },
  { id: 14, name: 'Laal Sev', price: '80', image: laalSev },
  { id: 15, name: 'Matree', price: '90', image: matree },
  { id: 16, name: 'Mini Samosa', price: '50', image: miniSamosa },
  { id: 17, name: 'Mixture Mild', price: '95', image: mixtureMild },
  { id: 18, name: 'Mixture Spicy', price: '100', image: mixtureSpicy },
  { id: 19, name: 'Mixture', price: '90', image: mixture },
  { id: 20, name: 'Moong Daal', price: '85', image: moongDaal },
  { id: 21, name: 'Moongfali Dane Spicy', price: '100', image: moongfaliDaneSpicy },
  { id: 22, name: 'Moti Danthal', price: '70', image: motiDanthal },
  { id: 23, name: 'Mungfali Daane Kali Mirch', price: '90', image: mungfaliDaaneKaliMirch },
  { id: 24, name: 'Ujjaini Sev', price: '95', image: ujjainiSev },
  { id: 25, name: 'Tasty Nuts', price: '120', image: tastyNuts },
  { id: 26, name: 'Sweet Chips', price: '85', image: sweetChips },
  { id: 27, name: 'Spicy Chips', price: '90', image: spicyChips },
  { id: 28, name: 'Shakkar Pare', price: '100', image: shakkarPare },
  { id: 29, name: 'Sev Spicy', price: '95', image: sevSpicy },
  { id: 30, name: 'Salted Chips', price: '80', image: saltedChips },
  { id: 31, name: 'Sabudana Mixture', price: '110', image: sabudanaMixture },
  { id: 32, name: 'Namkeen Papdi', price: '75', image: namkeenPapdi },
  { id: 33, name: 'Namkeen Pare', price: '85', image: namkeenPare },
  { id: 34, name: 'Palak Sev', price: '95', image: palakSev },
  { id: 35, name: 'Patli Danthal', price: '80', image: patliDanthal },
  { id: 36, name: 'Ratlami Sev', price: '100', image: ratlamiSev },
  { id: 37, name: 'Sabudana Mixture Spicy', price: '120', image: sabudanaMixtureSpicy },
];


const SweetsCategory = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const handleAdd = (item) => {
    const itemInCart = cart.find((cartItem) => cartItem.id === item.id);

    if (itemInCart) {
      dispatch(add({ ...item, quantity: itemInCart.quantity + 1 }));
    } else {
      dispatch(add({ ...item, quantity: 1 }));
    }

    toast.success('Added to cart successfully!', {
      position: 'bottom-right',
      style: { backgroundColor: 'orange', color: 'white' },
      icon: <span style={{ color: 'white' }}>✔️</span>,
    });
  };

  const filteredProducts = productsData.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div id="namkeen-6" className="ck_title_main_wrapper float_left">
      <div className="container" style={{marginTop:'69px'}}>
        <div className="row">
          <div className="ck_title_cont float_left">
            <p>Sweets</p>
          </div>
        </div>
      </div>

      <div className="btc_shop_indx_main_wrapper">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <div style={{ width: '24%' }} className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="jp_rightside_job_categories_wrapper jp_blog_right_box_search">
                <div className="jp_rightside_job_categories_heading">
                  <h4>Search</h4>
                </div>
                <div className="jp_blog_right_search_wrapper">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>

              {/* Filter Accordion */}
              <div className="cc_pc_first_accordion_wrapper cc_pc_second_accordion_wrapper">
                <div className="cc_pc_accordion">
                  <ul className="accordion">
                    <li className="default open">
                      <div className="link cc_product_heading">
                        Categories<i className="fa fa-chevron-down"></i>
                      </div>
                      <ul className="submenu">
                        <li>
                          <div className="content">
                            <div className="box">
                              {['Category 1', 'Category 2', 'Category 3'].map((item, index) => (
                                <p key={index}>
                                  <input type="checkbox" id={`cb${index}`} name="cb" />
                                  <label htmlFor={`cb${index}`}>{item}</label>
                                </p>
                              ))}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Product List */}
            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
              <div className="row">
                {filteredProducts.length > 0 ? (
                  filteredProducts.map((item) => (
                    <div key={item.id} className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="ck_elem_slide_img_wrapper float_left mt-0">
                        <div className="ck_elem_slide_img float_left">
                          <img src={item.image} alt={item.name} />
                        </div>
                        <div className="ck_elem_slide_img_cont float_left">
                          <h3>{item.name}</h3>
                          <p>{item.description}</p>
                          <div className="add-btn-price">
                            <p>Aenean sollicitudin, lorem quis</p>
                            <span style={{ display: 'flex', gap: '10px' }}>
                              <h5>500g</h5>
                              <h5>|</h5>
                              <h5>Price</h5>
                              <h5>₹{item.price}</h5>
                            </span>
                            <button
                              onClick={() => handleAdd(item)}
                              className="ck_bc_btn float_left"
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No products found.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SweetsCategory;

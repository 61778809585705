import React from 'react';
import Carousel from './Carousal/Carousel';
// TeamSlider Component
const TeamSlider = () => {
  return (
    <div className="btc_team_main_wrapper">
      <div className="container foutyparcent">
        {/* Left Section */}
        <div className="btc_team_left_wrapper">
          <h3>Taste Tradition of Ujjain</h3>
          <p>
          Experience the Taste Tradition of Ujjain with our authentic namkeen, perfect for travel, gatherings, or home. Savor the rich flavors of Ujjain’s culinary heritage in every bite!
          </p>
        </div>

        {/* Right Section with Slider */}
        <div className="btc_team_right_wrapper">
        <Carousel/>
        </div>
      </div>
    </div>
  );
};

export default TeamSlider;

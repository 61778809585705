import React, { useState, useEffect, useContext } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { DataContext } from '../HOOKS/Context';
import axios from 'axios';
import RoomCard from './RoomCard';

const AllRooms = () => {
  // const [adults, setAdults] = useState(0);
   // Adults state
  // const [children, setChildren] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(true);
  // const [rooms, setRooms] = useState([]);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [duplicateRooms, setDuplicateRooms] = useState([]);
  const { setDepartureDate, setArrivalDate, departureDate, arrivalDate } = useContext(DataContext);

  useEffect(() => {
    // Fetch room data from API
    const fetchRooms = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://jainshree-nakin-hotel-1.onrender.com/api/rooms/getall');
        // setRooms(response.data.rooms);
        setDuplicateRooms(response.data.rooms);
        setFilteredRooms(response.data.rooms);
      } catch (error) {
        console.error('Error fetching room data:', error);
      }
      setLoading(false);
    };

    fetchRooms();
  }, []);
  // const handleAdultsChange = (e) => {
  //   setAdults(Number(e.target.value));
  //   filterRooms();
  // };

  const filterRooms = () => {
    let availableRooms = duplicateRooms;

    // Filter by date
    if (arrivalDate && departureDate) {
      const arrival = moment(arrivalDate, 'DD-MM-YYYY').toDate();
      const departure = moment(departureDate, 'DD-MM-YYYY').toDate();
      
      availableRooms = availableRooms.filter(room => {
        return !room.currentbooking.some(booking => {
          const bookingArrival = moment(booking.arrivalDate, 'DD-MM-YYYY').toDate();
          const bookingDeparture = moment(booking.departureDate, 'DD-MM-YYYY').toDate();
          return moment(arrival).isBefore(bookingDeparture) && moment(departure).isAfter(bookingArrival);
        });
      });
    }

    // Filter by search key
    if (searchKey) {
      availableRooms = availableRooms.filter(room =>
        room.name.toLowerCase().includes(searchKey.toLowerCase())
      );
    }

    setFilteredRooms(availableRooms);
  };

  const handleArrivalDateChange = (date) => {
    const formattedDate = date ? date.format('DD-MM-YYYY') : '';
    setArrivalDate(formattedDate);
    filterRooms();
  };

  const handleDepartureDateChange = (date) => {
    const formattedDate = date ? date.format('DD-MM-YYYY') : '';
    setDepartureDate(formattedDate);
    filterRooms();
  };

  const handleSearchChange = (e) => {
    setSearchKey(e.target.value);
    filterRooms();
  };

  return (
    <>
      {loading && <div className="spinner green reverse"></div>}
      <div id="booking-2" className="tp-booking-area tp-falured-bg pt-50 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-booking-title-wrap mb-45 text-center p-relative">
                <h2 className="tp-booking-title">Reservations</h2>
                <span className="tp-booking-border p-absolute"></span>
              </div>
            </div>

            <div className="col-12">
              <form action="#">
                <div className="row">
                  {/* Arrival Date Picker */}
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-booking-form-input tp-booking-active pb-20 mr-5">
                      <p>Arrival Date</p>
                      <DatePicker
                        format="DD-MM-YYYY"
                        onChange={handleArrivalDateChange}
                        value={arrivalDate ? moment(arrivalDate, 'DD-MM-YYYY') : null}
                        placeholder="Select arrival date"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>

                  {/* Departure Date Picker */}
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-booking-form-input pb-20 mr-5">
                      <p>Departure Date</p>
                      <DatePicker
                        format="DD-MM-YYYY"
                        onChange={handleDepartureDateChange}
                        value={departureDate ? moment(departureDate, 'DD-MM-YYYY') : null}
                        placeholder="Select departure date"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>

                  {/* Static Adults Selection */}
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-booking-form-input pb-20 mr-5">
                      <p>Adults</p>
                      <input
                        type="number"
                        min="1"
                        // value={adults}
                      
                        placeholder="Number of adults"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>


                  {/* Search Input */}
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-booking-form-input pb-20 mr-5">
                      <p>Search Room by Name</p>
                      <input
                        type="text"
                        value={searchKey}
                        onChange={handleSearchChange}
                        placeholder="Enter room name"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>

                  {/* Search Button */}
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-booking-btn">
                      <button type="button" className="tp-btn-yellow" onClick={filterRooms}>Check Availability</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            {/* Room List */}
        
          </div>
        </div>
      </div>
      <div className="col-12 mt-5">
              <div className="room-list">
                {filteredRooms.map((room) => (
                  <RoomCard key={room._id} room={room} />
                ))}
              </div>
            </div>
    </>
  );
};

export default AllRooms;

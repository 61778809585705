import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { add } from '../redux/CartSlice';

import { Link } from 'react-router-dom';


// const data = [

//   {
//     id: 2,
//     title: 'Chakli',
//     price: '120',
//     image: chakli,
//     description: 'Aenean sollicitudin, lorem quis',
//   },
 
//   {
//     id: 6,
//     title: 'Chips Black Pepper',
//     price: '85',
//     image: chipsBlackPepper,
//     description: 'Aenean sollicitudin, lorem quis',
//   },

//   {
//     id: 12,
//     title: 'Gathiye',
//     price: '90',
//     image: gathiye,
//     description: 'Aenean sollicitudin, lorem quis',
//   },
//   {
//     id: 13,
//     title: 'Khatta Meetha Mixture',
//     price: '95',
//     image: khattaMeethaMixture,
//     description: 'Aenean sollicitudin, lorem quis',
//   },

//     {
//       id: 24,
//       title: 'Tasty Nuts',
//       price: '110',
//       image: tastyNuts,
//       description: 'Aenean sollicitudin, lorem quis',
//     },

//     {
//       id: 33,
//       title: 'Palak Sev',
//       price: '95',
//       image: palakSev,
//       description: 'Aenean sollicitudin, lorem quis',
//     },

  
//     ];
    

const Sweets = () => {




  // useEffect(() => {
  //   fetchdata();
  // }, []);

  // const fetchdata = async () => {
  //   const Res = await fetch('https://fakestoreapi.com/products');
  //   const data = await Res.json();
  //   setresdata(data);
  // };

  return (
    <div className="ck_bc_main_wrappers float_left mt-0">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="ck_heading_new_center_wrapper float_left">
              <h4>Our Sweets</h4>
              <h3>Sweets</h3>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="ck_elem_slide_img_wrapper float_left">
          <div className="ck_elem_slide_img float_left">
            <img src='' />
          
          </div>
          <div className="ck_elem_slide_img_cont float_left">
       
            <h3>Sweets</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <div className='add-btn-price'>

         <span style={{display:'flex', gap:'10px'}}> <h5> 500g  </h5> <h5>|</h5>   <h5>  Price</h5> <h5> ₹250</h5> </span>
            <button className="ck_bc_btn float_left">
          Add to Cart
            </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="ck_elem_slide_img_wrapper float_left">
          <div className="ck_elem_slide_img float_left">
            <img src='' />
          
          </div>
          <div className="ck_elem_slide_img_cont float_left">
       
            <h3>Sweets</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <div className='add-btn-price'>

         <span style={{display:'flex', gap:'10px'}}> <h5> 500g  </h5> <h5>|</h5>   <h5>  Price</h5> <h5> ₹250</h5> </span>
            <button className="ck_bc_btn float_left">
          Add to Cart
            </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="ck_elem_slide_img_wrapper float_left">
          <div className="ck_elem_slide_img float_left">
            <img src='' />
          
          </div>
          <div className="ck_elem_slide_img_cont float_left">
       
            <h3>Sweets</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <div className='add-btn-price'>

         <span style={{display:'flex', gap:'10px'}}> <h5> 500g  </h5> <h5>|</h5>   <h5>  Price</h5> <h5> ₹250</h5> </span>
            <button className="ck_bc_btn float_left">
          Add to Cart
            </button>
            </div>
          </div>
        </div>
      </div>

          <div className="col-md-12">
            <div className="ck_new_btn ck_new_btn_bc">
           <Link to="/SweetsCategory">  View All </Link> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sweets;

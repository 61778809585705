import moment from 'moment';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function Swiper({ id, departureDate, arrivalDate }) {

   const [room, setRoom] = useState();
   const arrivalDates = moment(arrivalDate, 'DD-YY-MMMM');
   const departureDates = moment(departureDate, 'DD-YY-MMMM');
   const [rentperday, setRentPerDay] = useState();
   const navigate = useNavigate();
   
   useEffect(() => {
      const fetchRooms = async () => {
         try {
            const response = await axios.post('https://jainshree-nakin-hotel-1.onrender.com/api/rooms/getroombyid', { roomid: id });
            setRoom(response.data);
            setRentPerDay(response.data.rentperday);
       
         } catch (error) {
            console.error('Error fetching room data:', error);
         }
      };

      fetchRooms();
   }, [id]);

   // Calculate the total days and amount
   const totalday = moment.duration(arrivalDates.diff(departureDates)).asDays();
   const totalamounts = totalday * rentperday;
   const username = JSON.parse(localStorage.getItem('user')).name;

   // Function to book the room and send WhatsApp message
   // async function bookroom() {
   //    const bookingdetails = {
   //       room,
   //       userid: JSON.parse(localStorage.getItem('user'))._id,
   //       departureDate,
   //       arrivalDate,
   //       totalday,
   //       totalamounts,
   //    };

   //    try {
   //       // Step 1: Make the booking API call
   //       const data = await axios.post('https://jainshree-nakin-hotel-1.onrender.com/api/bookings/bookroom', bookingdetails);
   //       console.log('Booking Data:', data); // Check the response from the booking API

   //       // Step 2: Send WhatsApp message with the booking details
   //       const messageDetails = {
   //          room,
   //          roomid: id,
   //          userid: JSON.parse(localStorage.getItem('user'))._id,
   //          departureDate,
   //          arrivalDate,
   //          totalday,
   //          totalamounts,
         
   //       };

   //       // Step 3: Send the message using the WhatsApp API
   //       await axios.post('http://localhost:5000/api/whatsappmsg/send-message', messageDetails);
   //       console.log('WhatsApp message sent successfully');

   //       // Step 4: Display success message and navigate
   //       swal.fire('Your booking was successful').then(() => {
   //          navigate('/HotelHome');
   //       });

   //    } catch (error) {
   //       console.error('Error during booking or sending message:', error);
   //       swal.fire('There was an error with your booking. Please try again.');
   //    }
   // }

   return (
      <>
         <div className="tp-room-details-area pt-100 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-lg-8">
                     <div className="tp-room-details-wrapper mb-35">
                        <div className="tp-room-details-slider mb-90">
                           <div className="swiper-container tp-room-details-slide-active pb-20">
                              <div className="swiper-wrapper">
                                 <div className="swiper-slide">
                                    <div className="tp-room-details-thumb">
                                       <img className="w-100" src="assets/img/room-details/room-1.jpg" alt="" />
                                    </div>
                                 </div>
                                 <div className="swiper-slide">
                                    <div className="tp-room-details-thumb">
                                       <img className="w-100" src="assets/img/room-details/room-1.jpg" alt="" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="tp-room-about-content mb-45">
                           <h2 className="tp-room-details-title mb-20">About This Room</h2>
                           <p>A good resort description should be clear, concise, and engaging...</p>
                        </div>
                        <div className="tp-room-about-feature mb-45">
                           <h2 className="tp-room-details-title mb-20">Check In:</h2>
                           <ul>
                              <li>The minimum age requirement for check-in is 18 years old.</li>
                              <li>Check-in time starts from 9:00 AM and is open anytime for guests.</li>
                              <li>Early check-in is subject to availability.</li>
                           </ul>
                        </div>
                        <div className="tp-room-about-amenities mb-65">
                           <h2 className="tp-room-details-title mb-30">Amenities</h2>
                           <div className="row tp-gx-23">
                              <div className="col-lg-6 col-md-6">
                                 <div className="tp-room-about-amenities-item mb-25">
                                    <h5 className="tp-room-about-amenities-title">Airport Pickup</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-4">
                     <div className="tp-room-details-info-wrap box-bg mb-35">
                        <div className="tp-room-type-attributes">
                           <h3 className="tp-room-details-info-title mb-25">Information</h3>
                           <div className="row row-cols-2 tp-gx-15 mb-5">
                              <div className="col">
                                 <h5 className="tp-room-type-title">Arrival Date: {arrivalDate}</h5>
                              </div>
                              <div className="col">
                                 <h5 className="tp-room-type-title">Departure Date: {departureDate}</h5>
                              </div>
                              <div className="col">
                                 <h4 className="tp-room-type-title">Room ID: {id}</h4>
                              </div>
                              <div className="col">
                                 <h4>Total Days: {totalday}</h4>
                              </div>
                              <div className="col">
                                 <h4>Name: {username}</h4>
                              </div>
                           </div>
                        </div>
                        <div className="tp-room-regular-price-wrap mb-30 tp-room-regular-price-border">
                           <span className="tp-room-regular-price-title">Per Night:</span>
                           <span className="tp-room-regular-price">${rentperday}</span>
                           <span className="tp-room-regular-price-title">Total Amount:</span>
                           <span className="tp-room-regular-price">${totalamounts}</span>
                        </div>
                        <div className="tp-purches-btn">
                           <button  className="tp-btn-falured">Book Now</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default Swiper;

import React from 'react';
import andimg  from '../COMPONENT/img/hero/home3/and.png'
const HeroSection = () => {
  return (
    <>
      {/* Hero Area */}
      <div className="tp-hero-area tp-hero-3-spacing tp-hero-video-overly p-relative">
        {/* Hero Video Background */}
        <div className="tp-hero-video-wrap">
          <div
            
            className="tp-hero-five-video youtube-bg"
            data-property={`{videoURL:'hNN9Q3GuWEM'}`}
          ></div>
        </div>

        <div className="container p-relative">
          <div className="row">
            {/* Hero Text */}
            <div className="col-lg-9 mb-35">
              <div className="tp-hero-3-title-wrap">
                <h5
                  className="tp-hero-3-title-pre wow img-custom-anim-right"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.1s"
                >
                 Experience Holy City Ujjain
                </h5>
                <h2
                  className="tp-hero-3-title wow img-custom-anim-left"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.1s"
                >
                 With exclusiveness  <br />
                  <img src={andimg} alt="and" />{' '}
                  <span> Comfort!</span>
                </h2>
              </div>
            </div>
          </div>

          {/* Mouse Scroll Button */}
          <div className="tp-hero-mouse-scrool d-none d-sm-block text-right">
            <a id="booking" className="tp-hero-mouse-btn" href="#booking-2">
              <svg
                width="120"
                height="120"
                viewBox="0 0 120 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="60"
                  cy="60"
                  r="59"
                  stroke="url(#paint0_linear_1282_71331)"
                  strokeWidth="2"
                />
                <path
                  d="M60 50V70"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M68 62L60 70L52 62"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1282_71331"
                    x1="28.8"
                    y1="26.4"
                    x2="106.8"
                    y2="98.4"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#FAF5EF" stopOpacity="0" />
                    <stop offset="1" stopColor="#FAF5EF" />
                  </linearGradient>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </div>

      {/* Discount Section */}
      <div className="tp-hero-discount-wrap tp-yellow-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-hero-discount-content text-center">
                <p>
                  Book your summer stay with us on our website and{' '}
                  <a href="#">Get 20% Off</a> if you book 2 nights or more! Valid
                  from 1st May to 1st August.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../JAINSHREE/jainnamkin/web logo jain shree.png';
import '../COMPONENT/css/bootstrap.css';
import { AiOutlineMenu } from "react-icons/ai";
const Header = () => {
  const [storedUser, setStoredUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar state

  // Fetch user from localStorage on component mount
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
   //  if (user) {
   //    setStoredUser(user);
   //  }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

//   const handleLogout = () => {
//     localStorage.removeItem('user'); // Remove user data from localStorage
//     setStoredUser(null);
//      // Clear the storedUser state to reflect logout
//   };

  return (
    <>
      <header className="tp-header-height">
     
        <div
          id="header-sticky"
          className="tp-header-three-area p-relative z-index-1 tp-header-3-border header-sticky-white tp-transparent"
        >
          <div className="containe" style={{padding:'0px 42px'}}>
            <ul className=" align-items-center">
              {/* Left Section */}
        <li className='toggale'>
        <AiOutlineMenu onClick={toggleSidebar} color='white' height={150} />
        </li>
                <li className="first-li-header d-flex align-items-center justify-content-center">
            
                  <div className="tp-main-menu tp-main-menu-3 d-none d-lg-block">
                    <nav className="tp-mobile-menu-active">
                    <li className="tp-header-3-menu-bar">
                  
                  </li>
                      <ul>
                  <li>
                  <AiOutlineMenu onClick={toggleSidebar} color='white' height={150} />
                  </li>
                        <li>
                          <Link to="/HotelHome">Home</Link>
                        </li>
                        <li>
                          <Link to="/allrooms">Rooms</Link>
                        </li>
                        <li>
                          <Link to="/gallerySection">Gallery</Link>
                        </li>
                        <li>
                          <Link to="/Contect">Contact Us</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
              
              </li>

              {/* Center Section: Logo */}
          
                <li className="">
                  <a className="p-relative" href="index.html">
                    <img
                      data-width="155"
                      className="logo-normal"
                      src={logo}
                      alt="logo"
                      height={80}
                    />
                    {/* <img
                      data-width="155"
                      className="logo-sticky"
                      src={logo}
                      alt="logo"
                      height={40}
                    /> */}
                  </a>
                </li>
          

              {/* Right Section */}
              
                <li className="header-btn-sec">
                  <a
                    href="#booking-2"
                    className="tp-booking-form-btn-header"
                    type="button"
                    onClick={toggleSidebar} // Open sidebar on click
                  >
                    Book now
                  </a>
                </li>
            
            </ul>
          </div>
        </div>
      </header>

      {/* Sidebar Component */}
      <div
        className={`sidebar ${isSidebarOpen ? 'active' : ''}`}
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: '300px',
          height: '100%',
          background: '#fff',
          boxShadow: '0 0 10px rgba(0,0,0,0.2)',
          transform: isSidebarOpen ? 'translateX(0)' : 'translateX(100%)',
          transition: 'transform 0.3s ease',
          zIndex: 999,
          padding: '20px',
        }}
      >
        <button
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
          }}
          onClick={toggleSidebar}
        >
          &times;
        </button>
        <div class="tp-offcanvas-area tp-offcanvas-style-2 opened">
         <div class="tp-offcanvas-wrapper">
            <div class="tp-offcanvas-top d-flex align-items-center justify-content-between">
               <div class="tp-offcanvas-logo">
                  <a href="index.html">
                     <img data-width="155" src="assets/img/logo/logo-2.png" style={{width:"155px"}}/>
                  </a>
               </div>
               <div class="tp-offcanvas-close">
                  <button  onClick={toggleSidebar} class="tp-offcanvas-close-btn">
                     <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.19141 9.80762L27.5762 28.1924" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M9.19141 28.1924L27.5762 9.80761" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                     </svg>
                  </button>
               </div>
            </div>
            <div class="tp-offcanvas-main">
               <div class="tp-offcanvas-content">
                  <h3 class="tp-offcanvas-title">Hello There!</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </p>
               </div>
               <div class="tp-offcanvas-menu d-lg-none">
                  <nav><ul>
                                 <li class="p-lg-inherit">
                                    <a href="index.html">Our Hotel <i class="fa-regular fa-angle-down"></i></a>
                                    <div class="tp-mega-menu tp-megamenu-active-left">
                                       <div class="tp-main-has-submenu tp-main-has-submenu-2">
                                          <div class="row gx-6 row-cols-1 row-cols-md-2 row-cols-lg-5">
                                             <div class="col homemenu">
                                                <div class="homemenu-thumb mb-15">
                                                   <a href="index.html">
                                                      <img src="assets/img/menu-demo/demo.jpg" alt="" />
                                                   </a>
                                                </div>
                                                <div class="homemenu-content text-center">
                                                   <h4 class="homemenu-title">
                                                      <a href="index.html">Modern Hotel</a>
                                                   </h4>
                                                </div>
                                             </div>
                                             <div class="col homemenu">
                                                <div class="homemenu-thumb mb-15">
                                                   <a href="index-2.html">
                                                      <img src="assets/img/menu-demo/demo-2.jpg" alt="" />
                                                   </a>
                                                </div>
                                                <div class="homemenu-content text-center">
                                                   <h4 class="homemenu-title">
                                                      <a href="index-2.html">City Hotel</a>
                                                   </h4>
                                                </div>
                                             </div>
                                             <div class="col homemenu">
                                                <div class="homemenu-thumb mb-15">
                                                   <a href="index-3.html">
                                                      <img src="assets/img/menu-demo/demo-3.jpg" alt="" />
                                                   </a>
                                                </div>
                                                <div class="homemenu-content text-center">
                                                   <h4 class="homemenu-title">
                                                      <a href="index-3.html">Luxury Hotel</a>
                                                   </h4>
                                                </div>
                                             </div>
                                             <div class="col homemenu">
                                                <div class="homemenu-thumb mb-15">
                                                   <a href="index-4.html">
                                                      <img src="assets/img/menu-demo/demo-4.jpg" alt="" />
                                                   </a>
                                                </div>
                                                <div class="homemenu-content text-center">
                                                   <h4 class="homemenu-title">
                                                      <a href="index-4.html">Modern Resort</a>
                                                   </h4>
                                                </div>
                                             </div>
                                             <div class="col homemenu">
                                                <div class="homemenu-thumb mb-15">
                                                   <a href="index-5.html">
                                                      <img src="assets/img/menu-demo/demo-5.jpg" alt="" />
                                                   </a>
                                                </div>
                                                <div class="homemenu-content text-center">
                                                   <h4 class="homemenu-title">
                                                      <a href="index-5.html">Sea Hotel</a>
                                                   </h4>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 <button class="tp-menu-close"><i class="far fa-chevron-right"></i></button></li>
                                 <li>
                                    <a href="room-1.html">Rooms <i class="fa-regular fa-angle-down"></i></a>
                                    <ul class="tp-submenu submenu">
                                       <li><a href="room-1.html">Room One</a></li>
                                       <li><a href="room-2.html">Room Two</a></li>
                                       <li><a href="room-3.html">Room Three</a></li>
                                       <li><a href="room-details-1.html">Room Details One</a></li>
                                       <li><a href="room-details-2.html">Room Details Two</a></li>
                                       <li><a href="room-details-3.html">Room Details Three</a></li>
                                    </ul>
                                 <button class="tp-menu-close"><i class="far fa-chevron-right"></i></button></li>
                                 <li>
                                    <a href="index.html">Pages <i class="fa-regular fa-angle-down"></i></a>
                                    <div class="tp-mega-menu">
                                       <div class="tp-megamenu-small">
                                          <div class="row gx-6 row-cols-1 row-cols-lg-3">
                                             <div class="col">
                                                <div class="tp-megamenu-list">
                                                   <ul>
                                                      <li><a href="about.html">About Us</a></li>
                                                      <li><a href="gallery.html">Gallery</a></li>
                                                      <li><a href="pricing-plan.html">Pricing Plan</a></li>
                                                      <li><a href="blog-grid.html">Blog Grid</a></li>
                                                      <li><a href="blog-sidebar.html">Blog Sidebar</a></li>
                                                      <li><a href="blog-details.html">Blog Details</a></li>
                                                      <li><a href="blog-details-2.html">Blog Details Two</a></li>
                                                   </ul>
                                                </div>
                                             </div>
                                             <div class="col">
                                                <div class="tp-megamenu-list">
                                                   <ul>
                                                      <li><a href="faq.html">Faq</a></li>
                                                      <li><a href="destination.html">Destination</a></li>
                                                      <li><a href="destination-details.html">Destination Details</a></li>
                                                      <li><a href="offer.html">Offer</a></li>
                                                      <li><a href="contact.html">Contact</a></li>
                                                      <li><a href="privacy-policy.html">privacy-policy</a></li>
                                                   </ul>
                                                </div>
                                             </div>
                                             <div class="col">
                                                <div class="tp-megamenu-list">
                                                   <ul>
                                                      <li><a href="login.html">Login</a></li>
                                                      <li><a href="register.html">Register</a></li>
                                                      <li><a href="forgot.html">Forgot</a></li>
                                                      <li><a href="cart.html">Cart</a></li>
                                                      <li><a href="checkout.html">Checkout</a></li>
                                                      <li><a href="wishlist.html">Wishlist</a></li>
                                                   </ul>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 <button class="tp-menu-close"><i class="far fa-chevron-right"></i></button></li>
                              </ul></nav>
               </div>
               <div class="tp-offcanvas-gallery d-none d-lg-block">
                  <div class="row gx-2">
                     <div class="col-md-3 col-3">
                        <div class="tp-offcanvas-gallery-img fix">
                           <a href="#"><img src="assets/img/offcanvas/offcanvas-1.jpg" alt="" /></a>
                        </div>
                     </div>
                     <div class="col-md-3 col-3">
                        <div class="tp-offcanvas-gallery-img fix">
                           <a href="#"><img src="assets/img/offcanvas/offcanvas-2.jpg" alt="" /></a>
                        </div>
                     </div>
                     <div class="col-md-3 col-3">
                        <div class="tp-offcanvas-gallery-img fix">
                           <a href="#"><img src="assets/img/offcanvas/offcanvas-3.jpg" alt="" /></a>
                        </div>
                     </div>
                     <div class="col-md-3 col-3">
                        <div class="tp-offcanvas-gallery-img fix">
                           <a href="#"><img src="assets/img/offcanvas/offcanvas-4.jpg" alt="" /></a>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="tp-offcanvas-contact">
                  <h3 class="tp-offcanvas-title sm">Information</h3>
                  <ul>
                     <li><a href="tel:1245654">+ 4 20 7700 1007</a></li>
                     <li><a href="mailto:hello@diego.com">hello@housey.com</a></li>
                     <li><a href="#">Avenue de Roma 158b, Lisboa</a></li>
                  </ul>
               </div>
               <div class="tp-offcanvas-social">
                  <h3 class="tp-offcanvas-title sm">Follow Us</h3>
                  <ul>
                     <li>
                        <a href="#">
                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.25 1.5H4.75C2.95507 1.5 1.5 2.95507 1.5 4.75V11.25C1.5 13.0449 2.95507 14.5 4.75 14.5H11.25C13.0449 14.5 14.5 13.0449 14.5 11.25V4.75C14.5 2.95507 13.0449 1.5 11.25 1.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                              <path d="M10.6016 7.5907C10.6818 8.13166 10.5894 8.68414 10.3375 9.16955C10.0856 9.65497 9.68711 10.0486 9.19862 10.2945C8.71014 10.5404 8.15656 10.6259 7.61663 10.5391C7.0767 10.4522 6.57791 10.1972 6.19121 9.81055C5.80451 9.42385 5.54959 8.92506 5.46271 8.38513C5.37583 7.8452 5.46141 7.29163 5.70728 6.80314C5.95315 6.31465 6.34679 5.91613 6.83221 5.66425C7.31763 5.41238 7.87011 5.31998 8.41107 5.4002C8.96287 5.48202 9.47372 5.73915 9.86817 6.1336C10.2626 6.52804 10.5197 7.0389 10.6016 7.5907Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                              <path d="M11.5742 4.42578H11.5842" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                           </svg>
                        </a>
                     </li>
                     <li>
                        <a href="#">
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.50589 12.7494C4.57662 16.336 9.16278 17.5648 12.7494 15.4941C14.2113 14.65 15.2816 13.388 15.8962 11.9461C16.7895 9.85066 16.7208 7.37526 15.4941 5.25063C14.2674 3.12599 12.1581 1.82872 9.89669 1.55462C8.34063 1.366 6.71259 1.66183 5.25063 2.50589C1.66403 4.57662 0.435172 9.16278 2.50589 12.7494Z" stroke="currentColor" stroke-width="1.5"></path>
                              <path d="M12.7127 15.4292C12.7127 15.4292 12.0086 10.4867 10.5011 7.87559C8.99362 5.26451 5.28935 2.57155 5.28935 2.57155M5.68449 15.6124C6.79553 12.2606 12.34 8.54524 16.3975 9.43537M12.311 2.4082C11.1953 5.72344 5.75732 9.38453 1.71875 8.58915" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path>
                           </svg>
                        </a>
                     </li>
                     <li>
                        <a href="#">
                           <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 5.5715H6.33342C7.62867 5.5715 8.61917 6.56199 8.61917 7.85725C8.61917 9.15251 7.62867 10.143 6.33342 10.143H1.76192C1.30477 10.143 1 9.83823 1 9.38108V1.76192C1 1.30477 1.30477 1 1.76192 1H5.5715C6.86676 1 7.85725 1.99049 7.85725 3.28575C7.85725 4.58101 6.86676 5.5715 5.5715 5.5715H1Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10"></path>
                              <path d="M10.9062 7.09454H17.0016C17.0016 5.41832 15.6301 4.04688 13.9539 4.04688C12.2777 4.04688 10.9062 5.41832 10.9062 7.09454ZM10.9062 7.09454C10.9062 8.77076 12.2777 10.1422 13.9539 10.1422H15.2492" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                              <path d="M16.1125 1.44434H11.668" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                           </svg>
                        </a>
                     </li>
                     <li>
                        <a href="#">
                           <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.75 13H5.25C3 13 1.5 11.5 1.5 9.25V4.75C1.5 2.5 3 1 5.25 1H12.75C15 1 16.5 2.5 16.5 4.75V9.25C16.5 11.5 15 13 12.75 13Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                              <path d="M8.70676 5.14837L10.8006 6.40465C11.5543 6.90716 11.5543 7.66093 10.8006 8.16344L8.70676 9.41972C7.86923 9.92224 7.19922 9.50348 7.19922 8.5822V6.06964C7.19922 4.98086 7.86923 4.64585 8.70676 5.14837Z" fill="currentColor"></path>
                           </svg>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
      </div>

      {/* Overlay to close sidebar */}
      {isSidebarOpen && (
        <div
          className="sidebar-overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.5)',
            zIndex: 998,
          }}
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';
import SnackItem from './NamkinItem';
import { useDispatch, useSelector } from 'react-redux';
import { add } from '../redux/CartSlice';
import boondiSpicy from './jainnamkin/jain shree products edited/boondi-spicy.png';
import chakli from './jainnamkin/jain shree products edited/chakli.png';
import chanaDaalSpicy from './jainnamkin/jain shree products edited/chana daal- spicy.png';
import chanaDaalPudhina from './jainnamkin/jain shree products edited/chana daal- pudina flavor.png';
import cheewda from './jainnamkin/jain shree products edited/cheewda.png';
import chipsBlackPepper from './jainnamkin/jain shree products edited/chips- black pepper.png';
import daalMoth from './jainnamkin/jain shree products edited/daal moth.png';
import danthalSev from './jainnamkin/jain shree products edited/danthal sev.png';
import fariyaliMixtureSpicy from './jainnamkin/jain shree products edited/fariyali mixture- spicy (2).png';
import fariyaliMixtureSweet from './jainnamkin/jain shree products edited/fariyali mixture- sweet.png';
import feekiSev from './jainnamkin/jain shree products edited/feeki sev.png';
import gathiye from './jainnamkin/jain shree products edited/gathiye.png';
import khattaMeethaMixture from './jainnamkin/jain shree products edited/khatta-meetha mixture.png';
import laalSev from './jainnamkin/jain shree products edited/laal sev.png';
import matree from './jainnamkin/jain shree products edited/matree.png';
import miniSamosa from './jainnamkin/jain shree products edited/mini samosa.png';
import mixtureMild from './jainnamkin/jain shree products edited/mixture- mild.png';
import mixtureSpicy from './jainnamkin/jain shree products edited/mixture- spicy.png';
import mixture from './jainnamkin/jain shree products edited/mixture.png';
import moongDaal from './jainnamkin/jain shree products edited/moong daal.png';
import moongfaliDaneSpicy from './jainnamkin/jain shree products edited/moongfali dane- spicy.png';
import motiDanthal from './jainnamkin/jain shree products edited/moti danthal.png';
import mungfaliDaaneKaliMirch from './jainnamkin/jain shree products edited/mungfali daane- kali mirch.png';
import ujjainiSev from './jainnamkin/jain shree products edited/ujjaini  sev.png';
import tastyNuts from './jainnamkin/jain shree products edited/tasty nuts.png';
import sweetChips from './jainnamkin/jain shree products edited/sweet chips.png';
import spicyChips from './jainnamkin/jain shree products edited/spicy chips.png';
import shakkarPare from './jainnamkin/jain shree products edited/shakkar pare.png';
import sevSpicy from './jainnamkin/jain shree products edited/sev- spicy.png';
import saltedChips from './jainnamkin/jain shree products edited/salted chips.png';
import sabudanaMixture from './jainnamkin/jain shree products edited/sabudana mixture.png';
import namkeenPapdi from './jainnamkin/jain shree products edited/namkeen papdi.png';
import namkeenPare from './jainnamkin/jain shree products edited/namkeen pare.png';
import palakSev from './jainnamkin/jain shree products edited/palak sev.png';
import patliDanthal from './jainnamkin/jain shree products edited/patli danthal.png';
import ratlamiSev from './jainnamkin/jain shree products edited/ratlami sev.png';
import sabudanaMixtureSpicy from './jainnamkin/jain shree products edited/sabudana mixture- spicy.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';



const data = [

  {
    id: 2,
    title: 'Chakli',
    price: '120',
    image: chakli,
    description: 'Aenean sollicitudin, lorem quis',
  },

  {
    id: 6,
    title: 'Chips Black Pepper',
    price: '85',
    image: chipsBlackPepper,
    description: 'Aenean sollicitudin, lorem quis',
  },

  {
    id: 12,
    title: 'Gathiye',
    price: '90',
    image: gathiye,
    description: 'Aenean sollicitudin, lorem quis',
  },
  {
    id: 13,
    title: 'Khatta Meetha Mixture',
    price: '95',
    image: khattaMeethaMixture,
    description: 'Aenean sollicitudin, lorem quis',
  },

  {
    id: 24,
    title: 'Tasty Nuts',
    price: '110',
    image: tastyNuts,
    description: 'Aenean sollicitudin, lorem quis',
  },

  {
    id: 33,
    title: 'Palak Sev',
    price: '95',
    image: palakSev,
    description: 'Aenean sollicitudin, lorem quis',
  },


];


const SnacksList = () => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [resdata, setresdata] = useState([]);

  const handleAdd = (item) => {
    const itemInCart = cart.find((cartItem) => cartItem.id === item.id);

    if (itemInCart) {
      // Update quantity if the item is already in the cart
      dispatch(add({ ...item, quantity: itemInCart.quantity + 1 }));
      toast.success("Added to cart successfully!", {
        position: "bottom-right",
        style: { backgroundColor: "orange", color: "white" },
      });
    } else {
      // Add the item with quantity 1 if it's not in the cart
      dispatch(add({ ...item, quantity: 1 }));
      toast.success("Added to cart successfully!", {
        position: "bottom-right",
        style: { backgroundColor: "orange", color: "white" },
        icon: <span style={{ color: "white" }}>✔️</span>, // Custom white icon
      });
    }

  };

  // useEffect(() => {
  //   fetchdata();
  // }, []);

  // const fetchdata = async () => {
  //   const Res = await fetch('https://fakestoreapi.com/products');
  //   const data = await Res.json();
  //   setresdata(data);
  // };

  return (
    <div className="ck_bc_main_wrapper float_left">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="ck_heading_new_center_wrapper float_left">
              <h4> Our Products </h4>
              <h3>Namkeen</h3>
            </div>
          </div>

          {data.map((item, index) => (
            <SnackItem
              key={index}
              item={item}
              handleAdd={handleAdd} // Pass the handleAdd function to the child component
            />
          ))}
          
          <ToastContainer />

          <div className="col-md-12">
            <div className="ck_new_btn ck_new_btn_bc">
              <Link to="/Products">  View All </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnacksList;

import React from 'react';

import room1 from "./img/Untitled design/1.png";
import room2 from "./img/Untitled design/2.png";
import room3 from "./img/Untitled design/3.png";
function DistanceExprince() {
  return (
    <>
     <div class="tp-service-area" style={{padding: '80px 0px'}}>
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="tp-room-title-wra text-center mb-50 wow fadeInUp" data-wow-delay=".3s" data-wow-duration="1s" style={{visibility:'visible', animationDuration:'1s', animationDelay:'0.3s', animationName:'fadeInUp'}} >
                        <h5 class="tp-section-three-title-pre">Exceptional Locality</h5>
                        <h2 class="tp-section-three-title mb-15">Temples   &amp; Their Distances</h2>
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-6 mb-30">
                     <div class="tp-service-3-wrap p-relative">
                        <div class="tp-service-3-thumb">
                <img class="w-100" src={room1} alt="service"/>
                        </div>
                        <div class="tp-service-3-content text-center">
                           <h3 class="tp-service-3-title">Shree Mahakaleshwar Mandir</h3>
                           <div class="tp-service-3-para pt-10">
                           
                              <a class="tp-btn-4" style={{color:'white'}}>50m away</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-6 mb-30">
                     <div class="tp-service-3-wrap p-relative">
                        <div class="tp-service-3-thumb">
                           <img class="w-100" src={room2} alt="service"/>
                        </div>
                        <div class="tp-service-3-content text-center">
                           <h3 class="tp-service-3-title">Shree Kal Bhairav Mandir</h3>
                           <div class="tp-service-3-para pt-10">
                            
                              <a class="tp-btn-4"style={{color:'white'}} >5.6km away</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-6 mb-30">
                     <div class="tp-service-3-wrap p-relative">
                        <div class="tp-service-3-thumb">
                           <img class="w-100" src={room3} alt="service"/>
                        </div>
                        <div class="tp-service-3-content text-center">
                           <h3 class="tp-service-3-title">Shree Mangalnath Mandir</h3>
                           <div class="tp-service-3-para pt-10">
                           
                              <a class="tp-btn-4" style={{color:'white'}} > 5.8km away</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
          </div>
    </>
  );
}

export default DistanceExprince;

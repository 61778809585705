import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import RoomCard from './RoomCard';
import { DataContext } from '../HOOKS/Context';
import moment from 'moment';
import { Link } from 'react-router-dom';
const RoomAndSuiteSection = () => {
  const [rooms, setRooms] = useState([]);  // Stores filtered rooms
  const [duplicateRooms, setDuplicateRooms] = useState([]);  // Stores all rooms from API
  const [loading, setLoading] = useState(true);  // Loading state
  const { departureDate, arrivalDate } = useContext(DataContext);  // Get selected dates from context

  // Fetch room data from API
  useEffect(() => {
    const fetchRooms = async () => {  
      setLoading(true); // Start loading
      try {
        const response = await axios.get('https://jainshree-nakin-hotel-1.onrender.com/api/rooms/getall');
        setRooms(response.data.rooms);  // Set initial rooms
        setDuplicateRooms(response.data.rooms);  // Store a copy of rooms for filtering
        console.log(response, 'read');
      } catch (error) {
        console.error('Error fetching room data:', error);
      } finally {
        setLoading(false); // Stop loading after the data is fetched
      }
    };

    fetchRooms();
  }, []);

  // Filter rooms based on availability
  const filterRooms = () => {
    const tempRooms = [];

    // Loop through all rooms
    for (const room of duplicateRooms) {
      let isAvailable = true;

      // Check if the room has any bookings
      if (room.currentbooking && room.currentbooking.length > 0) {
        for (const booking of room.currentbooking) {
          const bookingStart = moment(booking.arrivalDate);
          const bookingEnd = moment(booking.departureDate);

          const userArrival = moment(arrivalDate);
          const userDeparture = moment(departureDate);

          // Check for any overlap between the user's selected dates and the booking dates
          if (
            userArrival.isBetween(bookingStart, bookingEnd, null, '[)') || // User arrival is during a booking
            userDeparture.isBetween(bookingStart, bookingEnd, null, '(]') || // User departure is during a booking
            bookingStart.isBetween(userArrival, userDeparture, null, '[)') || // Booking starts during user's stay
            bookingEnd.isBetween(userArrival, userDeparture, null, '(]') || // Booking ends during user's stay
            userArrival.isSame(bookingStart) || userArrival.isSame(bookingEnd) || // Exact match with booking start or end
            userDeparture.isSame(bookingStart) || userDeparture.isSame(bookingEnd)
          ) {
            isAvailable = false; // Room is unavailable
            break;
          }
        }
      }

      // If no conflicts, add the room to the available rooms list
      if (isAvailable) {
        tempRooms.push(room);
      }
    }

    // Update the rooms state with only available rooms
    setRooms(tempRooms);
  };

  // Re-filter rooms when arrivalDate or departureDate changes
  useEffect(() => {
    if (arrivalDate && departureDate) {
      filterRooms();
    }
  }, [arrivalDate, departureDate]);  // Trigger re-filtering when dates change

  return (
    <div className="tp-room-area fix pb-140">
      <div className="container">
        <div className="row mb-20">
          <div className="col-lg-9 col-md-9 mb-15">
            <div className="tp-room-title-wrap">
              <h5 className="tp-section-three-title-pre">Luxury Resort</h5>
              <h2 className="tp-section-three-title mb-15">Rooms & Suites</h2>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 mb-40">
            <div className="tp-room-btn d-flex justify-content-end align-items-end h-100">
              <Link to="/allrooms">
                <a className="tp-btn-4" href="room-1.html">All Rooms</a>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="tp-room-slider-wrap">
            <div className="tp-room-slider-active swiper-container">
              {/* Display loading indicator or filtered rooms */}
              {loading ? (
               <div class="spinner green reverse"></div> // Loading indicator
              ) : (
                rooms.length > 0 ? (
                  rooms.map((room) => (
                    <RoomCard key={room._id} room={room} arrivalDate={arrivalDate} departureDate={departureDate} />
                  ))
                ) : (
                  <p>No rooms available for the selected dates.</p>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomAndSuiteSection;

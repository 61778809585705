import moment from 'moment';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
function Roomabout() {
   const {id
      ,departureDate,arrivalDate
      } = useParams();
      const [totalamount, setTotalAmount] = useState();
   const [room, setRoom] = useState();
   const arrivalDates = moment(arrivalDate, 'DD-YY-MMMM');
   const departureDates = moment(departureDate, 'DD-YY-MMMM');
   const [rentperday, setRentPerDay] = useState();
   const navigate = useNavigate();

   useEffect(() => {
      const fetchRooms = async () => {
         try {
            const response = await axios.post('https://jainshree-nakin-hotel-1.onrender.com/api/rooms/getroombyid', { roomid: id });
            setRoom(response.data);
            setRentPerDay(response.data.rentperday);
            setTotalAmount(response.data.rentperday); // Assuming you get rent per day
         } catch (error) {
            console.error('Error fetching room data:', error);
         }
      };

      fetchRooms();
   }, [id]);

   // Calculate the total days and amount
   const totalday = moment.duration(arrivalDates.diff(departureDates)).asDays();
   const totalamounts = totalday * rentperday;
  

   // Function to book the room and send WhatsApp message
   async function bookroom() {
      const userid = '1234567'
      const bookingdetails = {
         room,
         userid,
         departureDate,
         arrivalDate,
         totalday,
         totalamounts,
      };
   
      try {
         // Step 1: Make the booking API call
         const data = await axios.post('https://jainshree-nakin-hotel-1.onrender.com/api/bookings/bookroom', bookingdetails);
         console.log('Booking Data:', data); // Check the response from the booking API
   
         // Step 2: Display success message and navigate
         swal.fire('Your booking was successful').then(() => {
            navigate('/HotelHome');
         });
   
      } catch (error) {
         console.error('Error during booking:', error);
         swal.fire('There was an error with your booking. Please try again.');
      }
   }
   
  return (
   <>
   <div id="booking" class="tp-room-details-area pb-70">
            <div class="container">
               <div class="row">
                  <div class="col-lg-8 order-1 order-lg-0">
                     <div class="tp-room-details-left">
                        <div class="tp-room-info-wrap tp-room-info-space">
                           <span class="tp-room-info-subtitle mb-10">Double room</span>
                           <h2 class="tp-room-info-title mb-15">Forn Gully Bungalow</h2>
                           <p class="mb-30">Describe the different types of rooms available, such as standard rooms, suites, and villas.</p>
                           <div class="tp-room-info">
                              <ul>
                                 <li>
                                    <span class="tp-room-info-icon tp-room-info-border">
                                       <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M17.666 19.0001V7M17.666 19.0001L15.666 17.1053M17.666 19.0001L19.666 17.1053M17.666 7L15.666 8.89475M17.666 7L19.666 8.89475" stroke="#2D1A0E" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M1 3.00002H13M1 3.00002L2.89475 1M1 3.00002L2.89475 5.00003M13 3.00002L11.1053 1M13 3.00002L11.1053 5.00003" stroke="#2D1A0E" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M1 18.3335V7.66667C1 7.29848 1.29848 7 1.66667 7H12.3334C12.7017 7 13.0001 7.29848 13.0001 7.66667V18.3335C13.0001 18.7016 12.7017 19.0001 12.3334 19.0001H1.66667C1.29848 19.0001 1 18.7016 1 18.3335Z" stroke="#2D1A0E" stroke-width="0.8"></path>
                                       </svg>
                                    </span>
                                    <span class="tp-room-info-text">600 sq ft</span>
                                 </li>
                                 <li>
                                    <span class="tp-room-info-icon tp-room-info-border">
                                       <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M5.5 15.4002V14.5002C5.5 12.0149 7.51472 10.0002 10 10.0002C12.4853 10.0002 14.5 12.0149 14.5 14.5002V15.4002" stroke="#2D1A0E" stroke-width="0.8" stroke-linecap="round"></path>
                                          <path d="M9.99883 9.99983C11.49 9.99983 12.6989 8.79103 12.6989 7.29985C12.6989 5.80869 11.49 4.59985 9.99883 4.59985C8.50765 4.59985 7.29883 5.80869 7.29883 7.29985C7.29883 8.79103 8.50765 9.99983 9.99883 9.99983Z" stroke="#2D1A0E" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#2D1A0E" stroke-width="0.8"></path>
                                       </svg>
                                    </span>
                                    <span class="tp-room-info-text">4 People</span>
                                 </li>
                                 <li>
                                    <span class="tp-room-info-icon tp-room-info-border">
                                       <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M2.02857 7.86443V3.79663C2.02857 3.54239 2.13143 3.03392 2.54286 3.03392C2.95429 3.03392 4.77143 3.03392 5.62857 3.03392C6.14286 2.4407 8.20362 1 10 1C11.8 1 12.9829 1.4068 14.6286 3.03392H17.4571C17.7143 3.11866 18.2286 3.38985 18.2286 3.79663C18.2286 4.20341 18.2286 6.67799 18.2286 7.86443M2.02857 7.86443C1.68571 8.03391 1 8.5763 1 9.3898C1 9.8241 1 10.8571 1 11.9322M2.02857 7.86443H4.34286M18.2286 7.86443C18.8457 8.27122 19 9.0509 19 9.3898V11.9322M18.2286 7.86443H15.6571M1 11.9322H19M1 11.9322C1 12.8713 1 13.8424 1 14.4746H1.77143M4.34286 7.86443V5.32203C4.34286 5.0678 4.44571 4.55932 4.85714 4.55932C5.26857 4.55932 8.11429 4.55932 9.48571 4.55932C9.74286 4.55932 10 4.86441 10 5.0678M4.34286 7.86443H10M19 11.9322V14.4746H18.2286M15.6571 7.86443C15.6571 7.01696 15.6571 5.27119 15.6571 5.0678C15.6571 4.86441 15.4 4.55932 15.1429 4.55932H10.5143C10.2571 4.55932 10.0857 4.81356 10 5.0678M15.6571 7.86443H10M18.2286 14.4746H1.77143M18.2286 14.4746V16M1.77143 14.4746V16M10 5.0678C10 5.27119 10 7.01696 10 7.86443" stroke="#2D1A0E" stroke-width="0.8" stroke-linecap="round"></path>
                                       </svg>
                                    </span>
                                    <span class="tp-room-info-text">2 Beds</span>
                                 </li>
                                 <li>
                                    <span class="tp-room-info-icon tp-room-info-border">
                                       <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M1 10.1665H19.3333" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M18.418 10.1667L17.9189 13.3615C17.6291 15.2161 16.0317 16.5834 14.1545 16.5834H6.18145C4.30431 16.5834 2.70687 15.2161 2.41711 13.3615L1.91797 10.1667" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M4.78696 16.3149L3.75 17.4999" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M15.5469 16.3149L16.5839 17.4999" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.14942 3.75H9.35438C10.326 3.75 11.1886 4.37172 11.4959 5.29346C11.589 5.57299 11.5422 5.88028 11.3699 6.11932C11.1976 6.35836 10.9209 6.5 10.6262 6.5H7.87762C7.58296 6.5 7.30626 6.35836 7.13397 6.11932C6.96168 5.88028 6.91481 5.57299 7.00798 5.29346C7.31523 4.37172 8.17782 3.75 9.14942 3.75Z" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M9.24998 3.75C9.24998 2.23122 8.01878 1 6.5 1C4.98122 1 3.75 2.23122 3.75 3.75V10.1667" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </svg>
                                    </span>
                                    <span class="tp-room-info-text">2 Bathrooms</span>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div class="tp-room-about-content mb-25">
                           <h2 class="tp-room-details-title mb-20">About This Room</h2>
                           <p class="mb-30">A good resort description should be clear, concise, and engaging, capturing the attention of potential guests and giving them a sense of what it would be like to stay at the resort. It should also be informative, providing guests with the information they need to make an informed decision
                              about booking their stay suites, and villas.</p>
                           <div class="tp-room-details-toggle-btn">
                              <div id="show-more-content" class="tp-room-details-toggle" style={{display:'none'}}>
                                 <div class="tp-room-about-feature mb-45">
                                    <h2 class="tp-room-details-title mb-20">Check In:</h2>
                                    <ul>
                                       <li><span></span>The minimum age requirement for check-in is 18 years old.</li>
                                       <li><span></span>Check-in time starts from 9:00 AM and is open anytime for guests.</li>
                                       <li><span></span>Early check-in is subject to availability and can be requested by guests.</li>
                                    </ul>
                                 </div>
                                 <div class="tp-room-about-feature mb-45">
                                    <h2 class="tp-room-details-title mb-20">Check Out:</h2>
                                    <ul>
                                       <li><span></span>Check-out before noon.</li>
                                       <li><span></span>Express check-out.</li>
                                    </ul>
                                 </div>
                                 <div class="tp-room-about-feature mb-45">
                                    <h2 class="tp-room-details-title mb-20">Children and Extra Beds:</h2>
                                    <ul>
                                       <li><span></span>Children are welcome to stay at the resort.</li>
                                       <li><span></span>Kids stay for free when using existing bedding, but may not receive a complimentary breakfast.</li>
                                       <li><span></span>Rollaway/extra beds are available for $40 per day.</li>
                                    </ul>
                                 </div>
                              </div>
                              <button id="show-more" class="tp-btn-square mb-40">Read More</button>
                           </div>
                        </div>
                        <div class="tp-room-about-amenities mb-60">
                           <h2 class="tp-room-details-title mb-30">Amenities</h2>
                           <div class="row tp-gx-23">
                              <div class="col-lg-6 col-md-6">
                                 <div class="tp-room-about-amenities-item mb-25 d-flex align-items-center">
                                    <div class="tp-room-about-amenities-icon">
                                       <span>
                                          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path fill-rule="evenodd" clip-rule="evenodd" d="M35.3729 7.91525L28.661 14.6272L32.322 32.7288C32.5255 33.339 32.322 34.1526 31.7119 34.5593L30.2882 35.9831C29.2712 37 27.4406 36.5932 27.0339 35.3729L21.7457 21.7457L14.8305 28.661L15.2372 33.1356C15.2372 33.7458 15.0339 34.356 14.6272 34.7627L12.3898 37L8.11864 29.8813L1 25.6102L3.0339 22.9661C3.44068 22.5593 4.05084 22.3559 4.66102 22.3559L9.13559 22.7627L16.0508 15.8474L2.22034 10.5594C1 9.94919 0.59322 8.32203 1.61016 7.30508L3.0339 5.88136C3.44068 5.47458 4.25424 5.27118 4.8644 5.27118L22.9661 8.9322L29.678 2.22034C31.3051 0.59322 33.7458 0.59322 35.3729 2.22034C37 3.84746 37 6.28814 35.3729 7.91525Z" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <h5 class="tp-room-about-amenities-title">Airport Pickup</h5>
                                 </div>
                              </div>
                              <div class="col-lg-6 col-md-6">
                                 <div class="tp-room-about-amenities-item mb-25 d-flex align-items-center">
                                    <div class="tp-room-about-amenities-icon">
                                       <span>
                                          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M1 26.2189C3.12225 25.2763 5.5445 25.2763 7.6668 26.2189C9.789 27.1615 12.2113 27.1615 14.3335 26.2189C16.4558 25.2763 18.878 25.2763 21.0003 26.2189C23.1225 27.1615 25.5448 27.1615 27.667 26.2189C29.7893 25.2763 32.2115 25.2763 34.3338 26.2189" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M10.2588 10.2595H25.0738" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M10.2588 17.6667H25.0738" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M25.0752 21.3706V3.77782C25.0752 2.24446 26.3196 1 27.853 1C29.3864 1 30.6308 2.24446 30.6308 3.77782" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M10.2588 21.3706V3.77782C10.2588 2.24446 11.5032 1 13.0366 1C14.5699 1 15.8144 2.24446 15.8144 3.77782" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M1 33.6261C3.12225 32.6835 5.5445 32.6835 7.6668 33.6261C9.789 34.5687 12.2113 34.5687 14.3335 33.6261C16.4558 32.6835 18.878 32.6835 21.0003 33.6261C23.1225 34.5687 25.5448 34.5687 27.667 33.6261C29.7893 32.6835 32.2115 32.6835 34.3338 33.6261" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <h5 class="tp-room-about-amenities-title">Swimming Pool</h5>
                                 </div>
                              </div>
                              <div class="col-lg-6 col-md-6">
                                 <div class="tp-room-about-amenities-item mb-25 d-flex align-items-center">
                                    <div class="tp-room-about-amenities-icon">
                                       <span>
                                          <svg width="36" height="27" viewBox="0 0 36 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M17.999 26.3041L18.0157 26.2856" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M1 6.73747C11.2 -0.912489 24.8 -0.912489 35 6.73747" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M6.09961 13.5375C12.8996 8.43752 23.0997 8.43752 29.8996 13.5375" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M12.0498 19.4876C15.8755 17.1075 20.1245 17.1069 23.95 19.4876" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <h5 class="tp-room-about-amenities-title">Fiber Internet</h5>
                                 </div>
                              </div>
                              <div class="col-lg-6 col-md-6">
                                 <div class="tp-room-about-amenities-item mb-25 d-flex align-items-center">
                                    <div class="tp-room-about-amenities-icon">
                                       <span>
                                          <svg width="39" height="37" viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M24.0002 24.3335V22.389C24.0002 19.1671 21.3888 16.5557 18.1669 16.5557H8.44466C5.22272 16.5557 2.61133 19.1671 2.61133 22.389V24.3335" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1113 36.0001H6.50022C4.35161 36.0001 2.61133 34.2598 2.61133 32.1112V30.1667H24.0002V32.1112C24.0002 34.2598 22.2599 36.0001 20.1113 36.0001Z" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M0.666016 24.3335H25.9438" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M17.5519 16.5551L16.811 10.9784C16.6574 9.81183 17.5635 8.77734 18.7399 8.77734H35.6663C36.8505 8.77734 37.7585 9.82543 37.5913 10.9959L34.2585 34.3293C34.1205 35.2879 33.2999 35.9995 32.3335 35.9995H28.186" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M34.0166 1H29.8614C28.8969 1 28.0803 1.70583 27.9383 2.65861L27.0283 8.77778" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <h5 class="tp-room-about-amenities-title">Lunch &amp; Breakfast</h5>
                                 </div>
                              </div>
                              <div class="col-lg-6 col-md-6">
                                 <div class="tp-room-about-amenities-item mb-25 d-flex align-items-center">
                                    <div class="tp-room-about-amenities-icon">
                                       <span>
                                          <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5672 11.3785L11.3264 12.6886C10.3996 12.9204 9.46045 12.3569 9.22879 11.4301L8.16285 7.16648C8.0033 6.52826 8.02903 5.85767 8.23713 5.2335L9.25411 2.18272C9.48949 1.47641 10.1505 1 10.895 1H22.2395C22.984 1 23.645 1.47642 23.8805 2.18274L25.0567 5.71141C25.1607 6.0235 25.1736 6.35875 25.0938 6.67796L23.9058 11.4301C23.674 12.357 22.735 12.9204 21.8081 12.6887L16.5672 11.3785Z" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M32.134 16.5676V11.3784C32.134 8.51249 29.8107 6.18921 26.9448 6.18921H25.1445" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M8.06135 6.18921H6.18922C3.32329 6.18921 1 8.51249 1 11.3784V26.946C1 29.812 3.32329 32.1353 6.18922 32.1353H18.2974" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M23.2613 1.33728L16.5672 11.3784L9.87305 1.33728" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M16.6552 21.7571C16.6552 21.8051 16.6163 21.8439 16.5683 21.8439C16.5204 21.8439 16.4814 21.8051 16.4814 21.7571C16.4814 21.7091 16.5204 21.6703 16.5683 21.6703" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M16.5674 21.6703C16.6148 21.6703 16.6533 21.7089 16.6533 21.7563" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M16.6552 16.5682C16.6552 16.6161 16.6163 16.6551 16.5683 16.6551C16.5204 16.6551 16.4814 16.6161 16.4814 16.5682C16.4814 16.5202 16.5204 16.4813 16.5683 16.4813" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M16.5674 16.4813C16.6148 16.4813 16.6533 16.5198 16.6533 16.5672" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path fill-rule="evenodd" clip-rule="evenodd" d="M27.81 32.9999C27.81 29.6563 25.0994 26.9457 21.7559 26.9457C25.0994 26.9457 27.81 24.2353 27.81 20.8917C27.81 24.2353 30.5204 26.9457 33.864 26.9457C30.5204 26.9457 27.81 29.6563 27.81 32.9999Z" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <h5 class="tp-room-about-amenities-title">Airport Pickup</h5>
                                 </div>
                              </div>
                              <div class="col-lg-6 col-md-6">
                                 <div class="tp-room-about-amenities-item mb-25 d-flex align-items-center">
                                    <div class="tp-room-about-amenities-icon">
                                       <span>
                                          <svg width="40" height="19" viewBox="0 0 40 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M12.4689 1H6.80345C6.46004 1 6.18164 1.27847 6.18164 1.62181V17.6508C6.18164 17.9942 6.46004 18.2726 6.80345 18.2726H12.4689C12.8122 18.2726 13.0907 17.9942 13.0907 17.6508V1.62181C13.0907 1.27847 12.8122 1 12.4689 1Z" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M33.1964 1H27.531C27.1875 1 26.9092 1.27847 26.9092 1.62181V17.6508C26.9092 17.9942 27.1875 18.2726 27.531 18.2726H33.1964C33.5398 18.2726 33.8182 17.9942 33.8182 17.6508V1.62181C33.8182 1.27847 33.5398 1 33.1964 1Z" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M1 14.1963V5.0764C1 4.73306 1.2784 4.45459 1.62181 4.45459H5.55996C5.90338 4.45459 6.18177 4.73306 6.18177 5.0764V14.1963C6.18177 14.5398 5.90338 14.8181 5.55996 14.8181H1.62181C1.2784 14.8181 1 14.5398 1 14.1963Z" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M39.0001 14.1963V5.0764C39.0001 4.73306 38.7218 4.45459 38.3783 4.45459H34.4402C34.0967 4.45459 33.8184 4.73306 33.8184 5.0764V14.1963C33.8184 14.5398 34.0967 14.8181 34.4402 14.8181H38.3783C38.7218 14.8181 39.0001 14.5398 39.0001 14.1963Z" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M13.0908 9.63635H26.9088" stroke="#A05916" stroke-linecap="round" stroke-linejoin="round"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <h5 class="tp-room-about-amenities-title">Fitness Center</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="tp-room-map mb-30 fix">
                           <h2 class="tp-room-details-title mb-30">Location</h2>
                           <div class="tp-room-map-box">
                              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31078.361591144112!2d-74.0256365664179!3d40.705584751235754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1724572184688!5m2!1sen!2sbd" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                           </div>
                           <div class="tp-room-free-parking">
                              <p><i class="fa-sharp fa-solid fa-check"></i> Free street parking</p>
                           </div>
                        </div>
                        <div class="tp-room-about-feature mb-35">
                           <h2 class="tp-room-details-title mb-20">House Rules</h2>
                           <ul>
                              <li><span></span> Check-in time is 3:00 PM; check-out time is 11:00 AM.</li>
                              <li><span></span>No smoking allowed inside the room. Designated smoking areas are available outside.</li>
                              <li><span></span>Please conserve water and electricity by turning off lights and appliances when not in use.</li>
                              <li><span></span>Guests are responsible for locking the doors and securing their belongings.</li>
                              <li><span></span>Room keys must be returned at check-out. Lost keys incur a replacement fee.</li>
                           </ul>
                        </div>
                        <div class="tp-review-wrap mb-30">
                           <h2 class="tp-room-details-title mb-20">Guest Reviews</h2>
                           <div class="tp-review-inner mb-20">
                              <div class="tp-review-rating">
                                 <span class="tp-review-rating-star"><i class="fa-sharp fa-solid fa-star"></i></span>
                                 <h4 class="tp-review-rating-title">5/5 Excellent</h4>
                                 <span class="tp-review-rating-number">(3 reviews)</span>
                              </div>
                              <div class="row">
                                 <div class="col-lg-6 col-md-6">
                                    <div class="tp-review-rating-progress-wrap">
                                       <div class="tp-review-rating-progress">
                                          <span class="tp-review-rating-content">Staff</span>
                                          <div class="tp-review-rating-bar-item d-flex justify-content-between align-items-center">
                                             <div class="tp-review-rating-bar">
                                                <div class="single-progress" data-width="90%" style={{widows:'90%'}}></div>
                                             </div>
                                             <div class="tp-review-rating-bar-text">
                                               <span>4.8/5</span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="tp-review-rating-progress">
                                          <span class="tp-review-rating-content">Cleanliness</span>
                                          <div class="tp-review-rating-bar-item d-flex justify-content-between align-items-center">
                                             <div class="tp-review-rating-bar">
                                                <div class="single-progress" data-width="100%" style={{width:'100%'}}></div>
                                             </div>
                                             <div class="tp-review-rating-bar-text">
                                               <span>5/5</span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="tp-review-rating-progress">
                                          <span class="tp-review-rating-content">Check-in</span>
                                          <div class="tp-review-rating-bar-item d-flex justify-content-between align-items-center">
                                             <div class="tp-review-rating-bar">
                                                <div class="single-progress" data-width="90%" style={{width:'90%'}}></div>
                                             </div>
                                             <div class="tp-review-rating-bar-text">
                                               <span>4.5/5</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-lg-6 col-md-6">
                                    <div class="tp-review-rating-progress-wrap">
                                       <div class="tp-review-rating-progress">
                                          <span class="tp-review-rating-content">Facilities</span>
                                          <div class="tp-review-rating-bar-item d-flex justify-content-between align-items-center">
                                             <div class="tp-review-rating-bar">
                                                <div class="single-progress" data-width="100%" style={{width:'100%'}}></div>
                                             </div>
                                             <div class="tp-review-rating-bar-text">
                                               <span>5/5</span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="tp-review-rating-progress">
                                          <span class="tp-review-rating-content">Location</span>
                                          <div class="tp-review-rating-bar-item d-flex justify-content-between align-items-center">
                                             <div class="tp-review-rating-bar">
                                                <div class="single-progress" data-width="85%" style={{width:'85%'}}></div>
                                             </div>
                                             <div class="tp-review-rating-bar-text">
                                               <span>4.8/5</span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="tp-review-rating-progress">
                                          <span class="tp-review-rating-content">Value</span>
                                          <div class="tp-review-rating-bar-item d-flex justify-content-between align-items-center">
                                             <div class="tp-review-rating-bar">
                                                <div class="single-progress" data-width="100%" style={{width:'100%'}}></div>
                                             </div>
                                             <div class="tp-review-rating-bar-text">
                                               <span>5/5</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="tp-review-count">
                              <p>3 reviews on this Hotel - Showing 1 to 3</p>
                           </div>
                        </div>
                        <div class="tp-room-review-list mb-25">
                           <div class="tp-room-review-item">
                              <div class="tp-room-review-avater">
                                 <div class="tp-room-review-avater-thumb">
                                    <img src="assets/img/review/avatar.png" alt="review"/>
                                 </div>
                                 <div class="tp-room-review-avater-info">
                                    <div class="tp-room-review-rating">
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                    </div>
                                    <div class="tp-room-review-avater-content">
                                       <h4 class="tp-room-review-avater-title">Eleanor Fant <span>06 March, 2023 </span></h4>
                                       <p>Very nice sea view hotel, very clean room with basic kitchen with cooking facility . Good sleeping<br/>
                                          quality, will definitely come back again. You need to have a car.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="tp-room-review-item">
                              <div class="tp-room-review-avater">
                                 <div class="tp-room-review-avater-thumb">
                                    <img src="assets/img/review/avatar-2.png" alt="review"/>
                                 </div>
                                 <div class="tp-room-review-avater-info">
                                    <div class="tp-room-review-rating">
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                    </div>
                                    <div class="tp-room-review-avater-content">
                                       <h4 class="tp-room-review-avater-title">Theodore Handle <span>12 April, 2023 </span></h4>
                                       <p>If you want to get away from city life, rent a car and book Joyuam. It is a no-frills<br/>
                                          where you can enjoy fresh air and serene surrounding.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="tp-room-review-item">
                              <div class="tp-room-review-avater">
                                 <div class="tp-room-review-avater-thumb">
                                    <img src="assets/img/review/avatar.png" alt="review"/>
                                 </div>
                                 <div class="tp-room-review-avater-info">
                                    <div class="tp-room-review-rating">
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                       <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                    </div>
                                    <div class="tp-room-review-avater-content">
                                       <h4 class="tp-room-review-avater-title">Eleanor Fant <span>12 April, 2023 </span></h4>
                                       <p>Great value for money” Place was clean with everything you need provided.&nbsp;</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="tp-room-review-form-wrap">
                           <div class="tp-room-review-form-btn">
                              <button id="showlogin" class="tp-btn-4 mb-50">Write a review</button>
                           </div>
                           <div id="checkout-login" class="tp-room-review-form-content mb-50">
                              <h2 class="tp-room-details-title mb-10">Leave a comment</h2>
                              <p class="mb-30">Your email address will not be published. Required fields are marked *</p>
                              <div class="tp-review-rates-wrap mb-25">
                                 <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                       <div class="tp-review-clients">
                                          <div class="tp-review-item">
                                             <span class="tp-review-stats">Staff</span>
                                             <div class="tp-review-rates">
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                             </div>
                                          </div>
                                          <div class="tp-review-item">
                                             <span class="tp-review-stats">Cleanliness</span>
                                             <div class="tp-review-rates">
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                             </div>
                                          </div>
                                          <div class="tp-review-item">
                                             <span class="tp-review-stats">Check-in</span>
                                             <div class="tp-review-rates">
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                       <div class="tp-review-clients">
                                          <div class="tp-review-item">
                                             <span class="tp-review-stats">Facilities</span>
                                             <div class="tp-review-rates">
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                             </div>
                                          </div>
                                          <div class="tp-review-item">
                                             <span class="tp-review-stats">Location</span>
                                             <div class="tp-review-rates">
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                             </div>
                                          </div>
                                          <div class="tp-review-item">
                                             <span class="tp-review-stats">Value</span>
                                             <div class="tp-review-rates">
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                                <span class="tp-grey"><i class="fa-sharp fa-solid fa-star-sharp"></i></span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="tp-postbox-comment-input tp-postbox-comment-input-2">
                                 <form action="#">
                                    <div class="row">
                                       <div class="col-lg-6 col-md-6">
                                          <div class="tp-postbox-input mb-25">
                                             <label class="tp-label mb-5" for="name">Last Name&nbsp;*</label>
                                             <input class="tp-input" type="text" id="name" placeholder="Smith"/>
                                          </div>
                                       </div>
                                       <div class="col-lg-6 col-md-6">
                                          <div class="tp-postbox-input mb-25">
                                             <label class="tp-label mb-5" for="email">Email&nbsp;*</label>
                                             <input class="tp-input" type="email" id="email" placeholder="housey@mail.com"/>
                                          </div>
                                       </div>
                                       <div class="col-lg-12">
                                          <div class="tp-postbox-input mb-25">
                                             <label class="tp-label mb-5" for="title">Title*</label>
                                             <input class="tp-input" type="email" id="title" placeholder="Smith"/>
                                          </div>
                                       </div>
                                       <div class="col-lg-12">
                                          <div class="tp-postbox-textarea mb-15">
                                             <label class="tp-label mb-5" for="textarea">Your Comment&nbsp;*</label>
                                             <textarea class="tp-textarea" id="textarea" placeholder="Leave us a  Comment..."></textarea>
                                          </div>
                                       </div>
                                       <div class="col-lg-12">
                                          <div class="tp-postbox-agree mb-30 d-flex align-items-start mb-25">
                                             <input class="tp-checkbox" type="checkbox" id="agree"/>
                                             <label class="tp-agree" for="agree">I agree that my submitted data is being&nbsp;<a href="privacy-policy.html">collected and stored.</a></label>
                                          </div>
                                       </div>
                                       <div class="col-lg-12">
                                          <div class="tp-room-review-form-btn">
                                             <button class="tp-btn-4" type="submit">Post Review</button>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4 order-0 order-lg-1">
                     <div class="tp-room-booking-sidebar mb-50">
                        <form action="#">
                           <div class="tp-room-book-wrap mb-15">
                              <span class="tp-room-book-unit">From</span>
                              <h4 class="tp-room-book-price"><span class="dolar">₹</span>{rentperday} <span class="night">/night</span></h4>
                           </div>
                           <div class="tp-room-book-wrap tp-room-book-wrap-space">
                              <div class="tp-booking-form-input tp-booking-form-input-2 tp-booking-active pb-25 mr-5">
                                 <p>Check-In&nbsp;*</p>
                               <h5 className='arrivaldate-rd'>{arrivalDate}</h5>
                              </div>
                              <div class="tp-booking-form-input tp-booking-form-input-2 tp-booking-active pb-25 mr-5">
                                 <p>Check-Out&nbsp;*</p>
                                <h5 className='arrivaldate-rd'>{departureDate}</h5>
                              </div>
                              <div class="tp-booking-form-quantity tp-booking-form-quantity-2 pb-20 mr-5">
                                 <p class="tp-booking-form-para">Guests&nbsp;*</p>
                                 <div class="tp-hero-quantity p-relative">
                                    <span class="tp-booking-form-click tp-hero-quantity-click tp-hero-quantity-toggle">
                                       0 Guests(s)
                                       <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M11.9094 13.2732V11.9095C11.9094 11.1861 11.6221 10.4924 11.1106 9.98095C10.5991 9.46947 9.9054 9.18213 9.18206 9.18213H3.72735C3.00401 9.18213 2.3103 9.46947 1.79882 9.98095C1.28735 10.4924 1 11.1861 1 11.9095V13.2732" stroke="#A05916" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M6.45392 6.45471C7.96019 6.45471 9.18127 5.23363 9.18127 3.72735C9.18127 2.22108 7.96019 1 6.45392 1C4.94764 1 3.72656 2.22108 3.72656 3.72735C3.72656 5.23363 4.94764 6.45471 6.45392 6.45471Z" stroke="#A05916" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M16.0006 13.2729V11.9092C16.0001 11.3049 15.799 10.7179 15.4288 10.2403C15.0585 9.7627 14.5402 9.42158 13.9551 9.27051" stroke="#A05916" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M11.2285 1.08862C11.8152 1.23883 12.3352 1.58002 12.7065 2.05841C13.0778 2.53679 13.2794 3.12516 13.2794 3.73075C13.2794 4.33634 13.0778 4.9247 12.7065 5.40308C12.3352 5.88147 11.8152 6.22266 11.2285 6.37287" stroke="#A05916" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </svg>
                                    </span>
                                    <div class="tp-booking-form-quantity-list tp-hero-quantity-border tp-hero-quantity-active">
                                       <ul class="tp-booking-quantity-list tp-hero-quantity-list border-bottom">
                                          <li>
                                             <div class="tp-hero-quantity-content">
                                                <span>Adult</span>
                                                <p>Ages 12 or adove</p>
                                             </div>
                                             <div class="tp-hero-quantity-inner">
                                                <input class="tp-hero-quantity-input" type="text" value="1"/>
                                                <span class="tp-dreckment"><i class="fa-regular fa-angle-up"></i></span>
                                                <span class="tp-increment"><i class="fa-regular fa-angle-down"></i></span>
                                             </div>
                                          </li>
                                       </ul>
                                       <ul class="tp-hero-quantity-list">
                                          <li>
                                             <div class="tp-hero-quantity-content">
                                                <span>Child</span>
                                                <p>Ages 12 or adove</p>
                                             </div>
                                             <div class="tp-hero-quantity-inner">
                                                <input class="tp-hero-quantity-input" type="text" value="0"/>
                                                <span class="tp-dreckment"><i class="fa-regular fa-angle-up"></i></span>
                                                <span class="tp-increment"><i class="fa-regular fa-angle-down"></i></span>
                                             </div>
                                          </li>
                                       </ul>
                                       <div class="tp-hero-quantity-btn">
                                          <button type="button">OK</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="tp-purches-btn">
                                 <button onClick={bookroom} type="submit" class="tp-btn-border-black">Book</button>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
   </>
  );
}

export default Roomabout;

import React from 'react'
import BookingSection from './BookingInput'
import HeroSection from './SeconfSec'
import AboutAndBenefitsSection from './AboutAndBenefitsSection'
import RoomAndSuiteSection from './RoomAndSuiteSection'
import Exprincevd from './Exprincevd'
import DistanceExprince from './DistanceExprince'
function Home() {
  return (
    
  <>

  <HeroSection/>
  <BookingSection/>
  <AboutAndBenefitsSection/>
  
  <RoomAndSuiteSection/>
  {/* <Swiper/> */}
  <Exprincevd/>
  <DistanceExprince/>
  {/* <Gallery/> */}
  </>
  )
}

export default Home
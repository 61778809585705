import React from 'react';

function HotelContect() {
  return (
  <>
  <div class="tp-contact-item-area " style={{padding:'12px 25px'}}>
            <div class="container container-1430">
               <div class="tp-contact-bg">
                  <div class="row">
                     <div class="col-lg-12">
                        <div class="tp-contact-content mb-35 text-center">
                           <span class="tp-contact-subtitle tp-contact-subtitle-2 mb-20">Contact Us</span>
                           <h2 class="tp-contact-title tp-contact-title-2">Leave our team a message.</h2>
                           <p class="tp-contact-dis">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non enim<br/>
                              lacus. Nullam congue blandit quam, sit amet</p>
                        </div>
                     </div>
                     <div class="col-lg-4 col-md-6">
                        <div class="tp-contact-item mb-40">
                           <span class="tp-contact-icon"><i class="fa-sharp fa-solid fa-phone"></i></span>
                           <div class="tp-contact-text">
                              <h5>Phone</h5>
                              <span>Office. <a href="tel:+61234–5678910">+61 234–567 8910</a></span>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-4 col-md-6">
                        <div class="tp-contact-item mb-40">
                           <span class="tp-contact-icon"><i class="fa-regular fa-envelope"></i></span>
                           <div class="tp-contact-text">
                              <h5>Email</h5>
                              <a href="mailto:support@housey.com">support@housey.com</a>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-4 col-md-6">
                        <div class="tp-contact-item mb-40">
                           <span class="tp-contact-icon"><i class="fa-sharp fa-regular fa-location-dot"></i></span>
                           <div class="tp-contact-text">
                              <h5>Office</h5>
                              <a href="https://www.google.com/maps/place/102+Thompson+St,+New+York,+NY+10012,+USA/@40.7255437,-74.004061,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2598c338f691d:0x6d5f394e7a1962cc!8m2!3d40.7255397!4d-74.0018723">6391 Elgin St. Celina, Delaware.</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
  </>
  );
}

export default HotelContect;


import room1 from "./img/service/04.jpg";
import thumb1 from"./img/service/02.jpg";
import thumb2 from "./img/service/03.jpg";
import thumb3 from "./img/service/04.jpg";
import thumb4 from "./img/service/05.jpg";
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { DataContext } from '../HOOKS/Context';
import moment from 'moment';
import swal from 'sweetalert2';
const RoomDetails = () => {
  const images = [thumb1, thumb2, thumb3, thumb4];
  const thumbnails = [thumb1, thumb2, thumb3, thumb4];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  const [rooms, setRooms] = useState([]);  // Stores filtered rooms
  const [duplicateRooms, setDuplicateRooms] = useState([]);  // Stores all rooms from API
  const [loading, setLoading] = useState(true);  // Loading state
  const { departureDate, arrivalDate } = useContext(DataContext);  // Get selected dates from context

  // Fetch room data from API
  useEffect(() => {
    const fetchRooms = async () => {  
      setLoading(true); // Start loading
      try {
        const response = await axios.get('https://jainshree-nakin-hotel-1.onrender.com/api/rooms/getall');
        setRooms(response.data.rooms);  // Set initial rooms
        setDuplicateRooms(response.data.rooms);  // Store a copy of rooms for filtering
        console.log(response, 'read');
      } catch (error) {
        console.error('Error fetching room data:', error);
      } finally {
        setLoading(false); // Stop loading after the data is fetched
      }
    };

    fetchRooms();
  }, []);

  // Filter rooms based on availability
  const filterRooms = () => {
    const tempRooms = [];

    // Loop through all rooms
    for (const room of duplicateRooms) {
      let isAvailable = true;

      // Check if the room has any bookings
      if (room.currentbooking && room.currentbooking.length > 0) {
        for (const booking of room.currentbooking) {
          const bookingStart = moment(booking.arrivalDate);
          const bookingEnd = moment(booking.departureDate);

          const userArrival = moment(arrivalDate);
          const userDeparture = moment(departureDate);

          // Check for any overlap between the user's selected dates and the booking dates
          if (
            userArrival.isBetween(bookingStart, bookingEnd, null, '[)') || // User arrival is during a booking
            userDeparture.isBetween(bookingStart, bookingEnd, null, '(]') || // User departure is during a booking
            bookingStart.isBetween(userArrival, userDeparture, null, '[)') || // Booking starts during user's stay
            bookingEnd.isBetween(userArrival, userDeparture, null, '(]') || // Booking ends during user's stay
            userArrival.isSame(bookingStart) || userArrival.isSame(bookingEnd) || // Exact match with booking start or end
            userDeparture.isSame(bookingStart) || userDeparture.isSame(bookingEnd)
          ) {
            isAvailable = false; // Room is unavailable
            break;
          }
        }
      }

      // If no conflicts, add the room to the available rooms list
      if (isAvailable) {
        tempRooms.push(room);
      }
    }

    // Update the rooms state with only available rooms
    setRooms(tempRooms);
  };

  // Re-filter rooms when arrivalDate or departureDate changes
  useEffect(() => {
    if (arrivalDate && departureDate) {
      filterRooms();
    }
  }, [arrivalDate, departureDate]);  // Trigger re-filtering when dates change
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const handleDateValidation = () => {
    if (!arrivalDate || !departureDate) {
      swal.fire({
        icon: 'warning',
        title: 'Dates Missing',
        text: 'Please select both arrival and departure dates before booking.',
        confirmButtonText: 'OK',
      });
      return false;
    }
    return true;
  };

  return (
    <div id="booking-3" className="tp-room-details-area pt-100 pb-80">
      {/* Slider Section */}
      <div className="container"><div className="row">
      <div class="col-lg-12">
                     <div class="tp-room-title-wra text-center mb-50 wow fadeInUp" data-wow-delay=".3s" data-wow-duration="1s" style={{visibility:'visible', animationDuration:'1s', animationDelay:'0.3s', animationName:'fadeInUp'}} >
                  
                        <h2 class="tp-section-three-title mb-15" style={{textAlign:'start'}}>Room Discription</h2>
                     </div>
                  </div>
      <div className="col-lg-8 conti" >
      <div className="tp-room-details-thumb">
        <div className="slider-gallery">
          <div
            className="slider-wrapper"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
              display: "flex",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Room ${index + 1}`}
                className="slider-image"
                style={{ width: "100%", flexShrink: 0 }}
              />
            ))}
          </div>
        </div>
        <button onClick={handlePrev} className="slider-btn prev-btn">
          &#8249;
        </button>
        <button onClick={handleNext} className="slider-btn next-btn">
          &#8250;
        </button>
      </div>

      {/* Thumbnails */}
      <div className="thumbnails">
        {thumbnails.map((thumb, index) => (
          <img
         style={{height:'120px', width:'170px'}}
            key={index}
            src={thumb}
            alt={`Thumbnail ${index + 1}`}
            className={`thumbnail ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
      </div>
      {/* Room Information */}
      <div className="col-lg-4" >
      <div className="tp-room-details-info-wrap  box-bg mb-35">
      <div class="tp-room-type-attributes">
                           <h3 class="tp-room-details-info-title mb-25">Information</h3>
                           <div class="row row-cols-2 tp-gx-15 mb-5">
                              <div class="col">
                                 <div class="tp-room-type-capacity mb-20">
                                    <div class="tp-room-type-icon">
                                       <span>
                                          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M17.0712 18.3579V6.78589M17.0712 18.3579L15.1426 16.5307M17.0712 18.3579L18.9999 16.5307M17.0712 6.78589L15.1426 8.61304M17.0712 6.78589L18.9999 8.61304" stroke="#2D1A0E" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M1 2.92866H12.5719M1 2.92866L2.82715 1M1 2.92866L2.82715 4.85732M12.5719 2.92866L10.7448 1M12.5719 2.92866L10.7448 4.85732" stroke="#2D1A0E" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M1 17.715V7.42878C1 7.07372 1.28783 6.78589 1.64289 6.78589H11.9291C12.2842 6.78589 12.572 7.07372 12.572 7.42878V17.715C12.572 18.07 12.2842 18.3579 11.9291 18.3579H1.64289C1.28783 18.3579 1 18.07 1 17.715Z" stroke="#2D1A0E" stroke-width="0.8"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <h5 class="tp-room-type-title">600 sq ft</h5>
                                 </div>
                              </div>
                              <div class="col">
                                 <div class="tp-room-type-capacity mb-20">
                                    <div class="tp-room-type-icon">
                                       <span>
                                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M5.25 14.6002V13.7502C5.25 11.403 7.15279 9.50024 9.5 9.50024C11.8473 9.50024 13.75 11.403 13.75 13.7502V14.6002" stroke="#2D1A0E" stroke-width="0.8" stroke-linecap="round"></path>
                                             <path d="M9.49922 9.49988C10.9075 9.49988 12.0492 8.35823 12.0492 6.9499C12.0492 5.54158 10.9075 4.3999 9.49922 4.3999C8.09089 4.3999 6.94922 5.54158 6.94922 6.9499C6.94922 8.35823 8.09089 9.49988 9.49922 9.49988Z" stroke="#2D1A0E" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18Z" stroke="#2D1A0E" stroke-width="0.8"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <h5 class="tp-room-type-title">4 People</h5>
                                 </div>
                              </div>
                              <div class="col">
                                 <div class="tp-room-type-capacity mb-20">
                                    <div class="tp-room-type-icon">
                                       <span>
                                          <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M2.02857 7.86443V3.79663C2.02857 3.54239 2.13143 3.03392 2.54286 3.03392C2.95429 3.03392 4.77143 3.03392 5.62857 3.03392C6.14286 2.4407 8.20362 1 10 1C11.8 1 12.9829 1.4068 14.6286 3.03392H17.4571C17.7143 3.11866 18.2286 3.38985 18.2286 3.79663C18.2286 4.20341 18.2286 6.67799 18.2286 7.86443M2.02857 7.86443C1.68571 8.03391 1 8.5763 1 9.3898C1 9.8241 1 10.8571 1 11.9322M2.02857 7.86443H4.34286M18.2286 7.86443C18.8457 8.27122 19 9.0509 19 9.3898V11.9322M18.2286 7.86443H15.6571M1 11.9322H19M1 11.9322C1 12.8713 1 13.8424 1 14.4746H1.77143M4.34286 7.86443V5.32203C4.34286 5.0678 4.44571 4.55932 4.85714 4.55932C5.26857 4.55932 8.11429 4.55932 9.48571 4.55932C9.74286 4.55932 10 4.86441 10 5.0678M4.34286 7.86443H10M19 11.9322V14.4746H18.2286M15.6571 7.86443C15.6571 7.01696 15.6571 5.27119 15.6571 5.0678C15.6571 4.86441 15.4 4.55932 15.1429 4.55932H10.5143C10.2571 4.55932 10.0857 4.81356 10 5.0678M15.6571 7.86443H10M18.2286 14.4746H1.77143M18.2286 14.4746V16M1.77143 14.4746V16M10 5.0678C10 5.27119 10 7.01696 10 7.86443" stroke="#2D1A0E" stroke-width="0.8" stroke-linecap="round"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <h5 class="tp-room-type-title">2 Beds</h5>
                                 </div>
                              </div>
                              <div class="col">
                                 <div class="tp-room-type-capacity mb-20">
                                    <div class="tp-room-type-icon">
                                       <span>
                                          <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M1 10.1665H19.3333" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M18.417 10.1667L17.9179 13.3615C17.6281 15.2161 16.0307 16.5834 14.1535 16.5834H6.18047C4.30333 16.5834 2.70589 15.2161 2.41613 13.3615L1.91699 10.1667" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M4.78696 16.3149L3.75 17.4999" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M15.5469 16.3149L16.5839 17.4999" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path fill-rule="evenodd" clip-rule="evenodd" d="M9.14844 3.75H9.3534C10.325 3.75 11.1876 4.37172 11.4949 5.29346C11.588 5.57299 11.5412 5.88028 11.3689 6.11932C11.1966 6.35836 10.9199 6.5 10.6252 6.5H7.87664C7.58198 6.5 7.30528 6.35836 7.13299 6.11932C6.9607 5.88028 6.91383 5.57299 7.007 5.29346C7.31425 4.37172 8.17684 3.75 9.14844 3.75Z" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                             <path d="M9.24998 3.75C9.24998 2.23122 8.01878 1 6.5 1C4.98122 1 3.75 2.23122 3.75 3.75V10.1667" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <h5 class="tp-room-type-title">2 Bathrooms</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                    {rooms.map((item)=>(
                          <div class="tp-room-regular-price-wrap mb-30 tp-room-regular-price-border d-flex justify-content-between">
                          <span class="tp-room-regular-price-title">Per Night:</span>
                          <span class="tp-room-regular-price">₹{item.rentperday}</span>
                       </div>
                    ))}
                        <div class="tp-room-extra-services mb-40">
                           <h3 class="tp-room-details-info-title mb-30">Extra Services</h3>
                           <ul>
                              <li>
                                 <span>
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M12.8068 9.05005C12.5694 9.35927 12.2045 9.54418 11.8146 9.55254C11.4256 9.54183 11.0618 9.35744 10.8232 9.05005" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M8.17601 9.05005C7.93877 9.35907 7.57415 9.54387 7.18462 9.55254C6.79533 9.54193 6.43125 9.35754 6.19238 9.05005" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 17.1104C12.692 17.1087 15.4883 14.9719 16.3283 11.8925C17.28 11.7868 18 10.9824 18 10.0249C18 9.06732 17.28 8.26295 16.3283 8.15729C15.49 5.07659 12.6927 2.93872 9.5 2.93872C6.30727 2.93872 3.50994 5.07659 2.67167 8.15729C1.72001 8.26295 1 9.06732 1 10.0249C1 10.9824 1.72001 11.7868 2.67167 11.8925C3.51171 14.9719 6.30804 17.1087 9.5 17.1104Z" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M11.3898 12.5815C10.3073 13.5258 8.69381 13.5258 7.61133 12.5815" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M7.56152 1C8.07588 1 8.56918 1.20433 8.93289 1.56804C9.29661 1.93175 9.5009 2.42504 9.5009 2.93941" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M9.50098 2.93941C9.50098 2.42504 9.70526 1.93175 10.069 1.56804C10.4327 1.20433 10.926 1 11.4404 1" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                     </svg>
                                 </span>
                                 Kids Play Area
                              </li>
                              <li>
                                 <span>
                                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M10.0003 13.1536C13.2316 13.1536 15.8511 10.5341 15.8511 7.30274C15.8511 4.07141 13.2316 1.4519 10.0003 1.4519C6.76892 1.4519 4.14941 4.07141 4.14941 7.30274C4.14941 10.5341 6.76892 13.1536 10.0003 13.1536Z" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M3.57525 1C0.141584 4.49892 0.141584 10.1029 3.57525 13.6018" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M16.4248 1C19.8584 4.49892 19.8584 10.1029 16.4248 13.6018" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M9.04688 9.66543V4.9397H10.4501C11.1959 4.9397 11.8004 5.5442 11.8004 6.2899C11.8004 7.03559 11.1959 7.64012 10.4501 7.64012H9.04688" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                 </span>
                                 Free Private Parking
                              </li>
                              <li>
                                 <span>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M2.84082 15.4091H5.6297M5.6297 15.4091H8.4186M5.6297 15.4091V10.761" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M13.0674 15.4092V7.97219C13.0674 7.97219 15.3915 7.04257 15.3915 5.18331C15.3915 3.54963 15.3915 1 15.3915 1" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M13.0674 4.71851V1" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M1.44602 7.04257C2.37558 9.02087 5.62934 10.7611 5.62934 10.7611C5.62934 10.7611 8.8831 9.02087 9.8127 7.04257C10.8162 4.90683 9.8127 1 9.8127 1H1.44602C1.44602 1 0.442478 4.90683 1.44602 7.04257Z" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                     </svg>
                                 </span>
                                 Delicious Meals &amp; Snacks
                              </li>
                              <li>
                                 <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M14.6748 5.44458H15.6179C16.3824 5.44458 17.0011 6.06324 17.0011 6.82768V15.6179C17.0011 16.3814 16.3824 17.0001 15.6179 17.0001H5.93974C5.1753 17.0001 4.55664 16.3814 4.55664 15.617V14.3334" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path fill-rule="evenodd" clip-rule="evenodd" d="M1.88986 14.3333H11.9449C12.5396 14.3333 13.0942 14.0364 13.4241 13.5413L14.0764 12.5617C14.4658 11.9777 14.6738 11.2915 14.6738 10.5894V3.66669C14.6738 2.68448 13.8782 1.88892 12.8961 1.88892H4.00718C3.02497 1.88892 2.22942 2.68448 2.22942 3.66669V9.93869C2.22942 10.4907 2.10052 11.0347 1.8543 11.5289L1.09519 13.047C0.799194 13.6382 1.22942 14.3333 1.88986 14.3333Z" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M5.78223 1V2.77777" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M11.1152 1V2.77777" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M5.61328 6.33325H10.9466" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                       <path d="M5.61328 9.88892H10.9466" stroke="#5A5859" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                 </span>
                                 Free Early Check in
                              </li>
                           </ul>
                        </div>

                     {/* {rooms.map((room)=>(
                         <div key={room._id} class="tp-purches-btn">
                         <button class="tp-btn-falured"><a className="tp-btn-falured" href={`/RoomDetails/${room._id}/${arrivalDate}/${departureDate}`}>Book</a></button>
                      </div>
                      
                     ))} */}
                             {loading ? (
               <div class="spinner green reverse"></div> // Loading indicator
              ) : (
                rooms.length > 0 ? (
                  rooms.map((room) => (
                    <div key={room._id} class="tp-purches-btn">
                           {!arrivalDate || !departureDate ? (
                    <button
                    className="tp-btn-falured"
                    onClick={() => {
                      if (handleDateValidation()) {
                        window.location.href = `/detailsroom/${room._id}/${arrivalDate}/${departureDate}`;
                      }
                    }}
                  >
                    Book
                  </button>
              ) : (
                <button
                  className="tp-btn-falured"
                  onClick={() => {
                    if (handleDateValidation()) {
                      window.location.href = `/detailsroom/${room._id}/${arrivalDate}/${departureDate}`;
                    }
                  }}
                >
                  Book
                </button>
              )}
                 </div>
                  ))
                ) : (
                  <p>No rooms available for the selected dates.</p>
                )
              )}
          
      </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default RoomDetails;




const NamkinItem = ({ key , item ,handleAdd}) => {
    return (
      <div key={key} className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="ck_elem_slide_img_wrapper float_left">
          <div className="ck_elem_slide_img float_left">
            <img src={item.image} alt={item.title} />
          
          </div>
          <div className="ck_elem_slide_img_cont float_left">
       
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <div className='add-btn-price'>

         <span style={{display:'flex', gap:'10px'}}> <h5> 500g  </h5> <h5>|</h5>   <h5>  Price</h5> <h5> ₹{item.price}</h5> </span>
            <button onClick={()=>handleAdd(item)} className="ck_bc_btn float_left">
          Add to Cart
            </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default NamkinItem
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, List, Row, Col, Spin } from 'antd';

const ABooking = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get('https://jainshree-nakin-hotel-1.onrender.com/api/rooms/getallbookings');
        setBookings(response.data.bookingall);  // Assuming the bookings data is in 'bookingall'
        setLoading(false);
      } catch (error) {
        console.error('Error fetching booking data:', error);
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <h2>All Bookings</h2>
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <Row gutter={[16, 16]}>
          {bookings.length > 0 ? (
            bookings.map((booking) => (
              <Col span={8} key={booking._id}>
                <Card title={`Room: ${booking.room}`} bordered>
                  <List>
                    <List.Item><strong>Booking ID:</strong> {booking._id}</List.Item>
                    <List.Item><strong>Room ID:</strong> {booking.roomid}</List.Item>
                    <List.Item><strong>User ID:</strong> {booking.userid}</List.Item>
                    <List.Item><strong>Arrival Date:</strong> {booking.arrivalDate}</List.Item>
                    <List.Item><strong>Departure Date:</strong> {booking.departureDate}</List.Item>
                    <List.Item><strong>Status:</strong> {booking.status}</List.Item>
                  </List>
                </Card>
              </Col>
            ))
          ) : (
            <p>No bookings available.</p>
          )}
        </Row>
      )}
    </div>
  );
};

export default ABooking;

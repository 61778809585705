import React, { useState } from 'react';
import axios from 'axios'; // Make sure axios is installed
import { useNavigate } from 'react-router-dom'; // For navigation

function LoginForm() {
  const [email, setemail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate(); // Hook for navigation

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://jainshree-nakin-hotel-1.onrender.com/api/user/login', { email, password  });
      
      // Assuming your API returns a token or success message on successful login
      if (response.data) {
        setSuccessMessage('Login successful!');
        
        // Optionally store token or user info in localStorage/sessionStorage
        localStorage.setItem('user', JSON.stringify(response.data)); 

        // Redirect to the home page after a successful login
        navigate('/'); // Adjust the route based on your app's structure
      } else {
        setErrorMessage('Login failed. Please try again.');
      }

    } catch (error) {
      setErrorMessage('Invalid email or password.');
      console.error('Login error:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="wrapper">
        <form onSubmit={handleSubmit}>
          <h1>Login</h1>

          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {successMessage && <div className="success-message">{successMessage}</div>}

          <div className="input-box">
            <input
              type="text"
              placeholder="email"
              value={email}
              onChange={(e) => setemail(e.target.value)}
              required
            />
            <i className='bx bxs-user'></i>
          </div>

          <div className="input-box">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <i className='bx bxs-lock-alt'></i>
          </div>

          <div className="remember-forget">
            <label>
              <input type="checkbox" />
              Remember me
            </label>
            <a href="#">Forgot password?</a>
          </div>

          <button type="submit" className="btn">
            Login
          </button>

          <div className="register-link">
            <p>
              Don't have an account? <br />
              <a href="#">Register</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;

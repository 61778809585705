import React from 'react';
import { Tabs } from 'antd';
import AllBooking from './ABooking';
import {  useNavigate } from 'react-router-dom';

const { TabPane } = Tabs;

function AdminPanel() {
  const history = useNavigate();
  


  return (
    <div style={{ marginTop: '100px', padding: '200px' }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Bookings" key="1">
          <AllBooking />
        </TabPane>
        <TabPane tab="Rooms" key="2">
          <h1>Rooms Section</h1>
        </TabPane>
        <TabPane tab="Add Rooms" key="3">
          <h1>Add Rooms Section</h1>
        </TabPane>
        <TabPane tab="Users" key="4">
          <h1>Users Section</h1>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default AdminPanel;

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TestimonialSlider.css'; // Assuming you have a CSS file for styling
import clientimg from './images/WhatsApp Image 2024-10-18 at 5.51.08 PM (1).jpeg'
const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true, // If you want navigation arrows
  };

  return (
    <div className="ck_testi_main_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-5">
            <div className="ck_heading_new_center_wrapper">
              <h4>Check out our client’s</h4>
              <h3>Testimonial Words</h3>
            </div>
          </div>
          <div className="col-md-12">
            <div className="ck_testi_slider_wrapper">
              <Slider {...settings}>
              <div class="testimonial-container">
  <div class="btn" id="btn-prev"><i class="fa-solid fa-chevron-left"></i></div>
  <div class="btn" id="btn-next"><i class="fa-solid fa-chevron-right"></i></div>

  <div class="user">
    <img src={clientimg} alt="user" class="user-image" />
    <div class="user-details">
      <h4 class="username">Rohit Wadhwani </h4>
      <p class="role">Ujjain</p>
    </div>
  </div>
  <p class="testimonial">
  Their quality never disappoints! The namkeen is always fresh, perfectly spiced, and full of authentic Ujjaini flavor. It’s my go-to snack for every occasion, and my family loves it too. Truly the best in Ujjain!
  </p>

  <div class="progress-dots" id="progress-dots"></div>

</div>

                {/* Testimonial 2 */}
                <div class="testimonial-container">
  <div class="btn" id="btn-prev"><i class="fa-solid fa-chevron-left"></i></div>
  <div class="btn" id="btn-next"><i class="fa-solid fa-chevron-right"></i></div>

  <div class="user">
    <img src={clientimg} alt="user" class="user-image" />
    <div class="user-details">
      <h4 class="username">Rohit Wadhwani</h4>
      <p class="role">Ujjain</p>
    </div>
  </div>
  <p class="testimonial">
  Their quality never disappoints! The namkeen is always fresh, perfectly spiced, and full of authentic Ujjaini flavor. It’s my go-to snack for every occasion, and my family loves it too. Truly the best in Ujjain!
  </p>

  <div class="progress-dots" id="progress-dots"></div>

</div>

                {/* Testimonial 3 */}
                <div class="testimonial-container">
  <div class="btn" id="btn-prev"><i class="fa-solid fa-chevron-left"></i></div>
  <div class="btn" id="btn-next"><i class="fa-solid fa-chevron-right"></i></div>

  <div class="user">
    <img src={clientimg} alt="user" class="user-image" />
    <div class="user-details">
      <h4 class="username">Rohit Wadhwani</h4>
      <p class="role">Ujjain</p>
    </div>
  </div>
  <p class="testimonial">
  Their quality never disappoints! The namkeen is always fresh, perfectly spiced, and full of authentic Ujjaini flavor. It’s my go-to snack for every occasion, and my family loves it too. Truly the best in Ujjain!
  </p>

  <div class="progress-dots" id="progress-dots"></div>

</div>  

              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;

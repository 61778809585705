import React from 'react';

import room1 from "./img/hero/02.jpg";
function RoomDetails() {
  return (
    <>
      {/* Breadcrumb Section */}
      <div
        className="tp-breadcrumb-area tp-breadcrumb-spacing bg-position"
        style={{
          position: 'relative',
        }}
      >
        <div className="container p-relative">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-breadcrumb-title-wrap text-center">
                <h5 className="tp-breadcrumb-title-pre">Our Luxurious Resort & Accommodations</h5>
                <h2 className="tp-breadcrumb-title">Economy Classic Room</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Gallery Section */}
      <div className="tp-gallery-area-start pt-120 pb-95">
        <div className="container">
          <div
            className="row grid gx-25"
            style={{
              position: 'relative',
              height: '1052.19px',
            }}
          >
            {/* Gallery Images */}
            <div
              className="col-lg-4 col-md-6 col-sm-6 grid-item mb-25"
              style={{
                position: 'absolute',
                left: '0%',
                top: '0px',
              }}
            >
              <div className="tp-gallery-thumb">
                <img className="w-100" src={room1} alt="Gallery Image 1" />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6 grid-item mb-25"
              style={{
                position: 'absolute',
                left: '33.3333%',
                top: '0px',
              }}
            >
              <div className="tp-gallery-thumb">
                <img className="w-100" src={room1} alt="Gallery Image 2" />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6 grid-item mb-25"
              style={{
                position: 'absolute',
                left: '66.6667%',
                top: '0px',
              }}
            >
              <div className="tp-gallery-thumb">
                <img className="w-100"src={room1} alt="Gallery Image 3" />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6 grid-item mb-25"
              style={{
                position: 'absolute',
                left: '0%',
                top: '264px',
              }}
            >
              <div className="tp-gallery-thumb">
                <img className="w-100" src={room1} alt="Gallery Image 4" />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6 grid-item mb-25"
              style={{
                position: 'absolute',
                left: '66.6667%',
                top: '264px',
              }}
            >
              <div className="tp-gallery-thumb">
                <img className="w-100" src={room1} alt="Gallery Image 5" />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6 grid-item mb-25"
              style={{
                position: 'absolute',
                left: '33.3333%',
                top: '522px',
              }}
            >
              <div className="tp-gallery-thumb">
                <img className="w-100" src={room1} alt="Gallery Image 6" />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6 grid-item mb-25"
              style={{
                position: 'absolute',
                left: '0%',
                top: '529px',
              }}
            >
              <div className="tp-gallery-thumb">
                <img className="w-100" src={room1} alt="Gallery Image 7" />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6 grid-item mb-25"
              style={{
                position: 'absolute',
                left: '33.3333%',
                top: '787px',
              }}
            >
              <div className="tp-gallery-thumb">
                <img className="w-100" src={room1} alt="Gallery Image 8" />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6 grid-item mb-25"
              style={{
                position: 'absolute',
                left: '66.6667%',
                top: '787px',
              }}
            >
              <div className="tp-gallery-thumb">
                <img className="w-100" src={room1} alt="Gallery Image 9" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RoomDetails;

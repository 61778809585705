import React from 'react';
import imageone from '../COMPONENT/img/banner img/1.png'
import imagetwo from '../COMPONENT/img/banner img/2.png'
import './css/Landingcss.css'
import logo from '../JAINSHREE/jainnamkin/web logo jain shree.png'
import { Link } from 'react-router-dom';
// import logoicon from '/'
const MainHome = () => {
  return (
    <div className="landing-page">


      <div className="container">
      <img src={logo} height={100} alt="" />
        {/* Section 1: Rooms and Banquets Bookings */}
        <div className='d-flex card-container'>
        <Link to='/HotelHome'>
        <div className="row section">
       
            <img
              src={imageone}
              alt="Rooms and Banquets Booking"
              className="section-image"
            />
          
          <div className="col-md-6 section-content">
            <h2>For booking Room
            </h2>
            <a   className="cta-button">
              VISIT WEBSITE
            </a>
          </div>
        </div>
        </Link>
        {/* Section 2: Sweets, Namkeen Restaurant */}
        <Link to='/Namkeen'>
        <div className="row section">
       
       <img
         src={imagetwo}
         alt="Rooms and Banquets Booking"
         className="section-image"
       />
     
     <div className="col-md-9 section-content">
       <h2>For ordering Namkeen and Sweets</h2>
       <a   className="cta-button">
         VISIT WEBSITE
       </a>
     </div>
   </div>
        </Link>
        </div>
      </div>
     
     
    </div>
  );
};

export default MainHome;

import React, { useState , useEffect, useRef } from 'react'
import './css/animate.css'
import './css/bootstrap.min.css'
import './css/demo.css'
// import './css/flaticon.css'
// import './css/font-awesome.css'
import './css/style.css'
import './css/style_VII.css'
import Herosec from "./Herosec";
import { useSelector } from "react-redux";
import logo from './jainnamkin/web logo jain shree.png'
import { Link } from "react-router-dom";
import { BsCart3 } from "react-icons/bs";
import testing from './testing'
import "./Navbar.css";
import ShapeOverlays from "./ShapeOverlays";
import "./HamburgerMenu.css";
import { FaBars } from "react-icons/fa";
function Navbar() {
  const cart = useSelector((state) => state.cart);
  const [isOpen, setIsOpen] = useState(false); // Controls the hamburger state
  const [showMenu, setShowMenu] = useState(false); // Controls dropdown menu visibility
  const overlayRef = useRef(null);
  const hamburgerRef = useRef(null);

  let overlay;

  useEffect(() => {
    overlay = new ShapeOverlays(overlayRef.current);

    const handleClick = () => {
      overlay.toggle();
      const navItems = document.querySelectorAll(".global-menu__item");
      if (overlay.isOpened) {
        hamburgerRef.current.classList.add("is-opened-navi");
        navItems.forEach((item) => item.classList.add("is-opened"));

        // Show dropdown menu after animation
        setTimeout(() => setShowMenu(true), 1600); // Delay matches animation duration
      } else {
        hamburgerRef.current.classList.remove("is-opened-navi");
        navItems.forEach((item) => item.classList.remove("is-opened"));
        setShowMenu(false);
      }
    };

    hamburgerRef.current.addEventListener("click", handleClick);
    return () => {
      hamburgerRef.current.removeEventListener("click", handleClick);
    };
  }, []);

  
  return (
    <>
      <div id="booking-5" className="ck_menu_inner float_left">
      
          <div className="ck_logo_wrapper ck_logo_wrapper_inner">
         
          <img  style={{height:'80px'}} src={logo} alt="" />
      
          </div>
          <div className="ck_navigation_right_wrapper">
            <div className="main main--demo-3">
              <div className="content content--demo-3">
        
                <div className="global-menu">
                  <h1>showing heading in this </h1>
                  <div className="global-menu__wrap" id="cssmenu">
                    <ul>
                      <li className="has-sub">
            
                    
                      </li>
                
                      <li className="has-sub">
                        <a className="global-menu__item global-menu__item--demo-3" href="#">
                          Products
                        </a>
                        <ul>
                          <li>
                            <a href="product.html" className="global-menu__item global-menu__item--demo-3">
                              Products
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a className="global-menu__item global-menu__item--demo-3" href="gallery.html">
                          Gallery
                        </a>
                      </li>
                      <li className="has-sub">
                        <a className="global-menu__item global-menu__item--demo-3" href="#">
                          Pages
                        </a>
                        <ul>
                          <li>
                            <a href="checout.html" className="global-menu__item global-menu__item--demo-3">
                              Checkout
                            </a>
                          </li>
                          <li>
                            <a href="cart.html" className="global-menu__item global-menu__item--demo-3">
                              Cart
                            </a>
                          </li>
                        </ul>
                      </li>
                
                      <li>
                        <a className="global-menu__item global-menu__item--demo-3" href="contact.html">
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <svg className="shape-overlays" viewBox="0 0 100 100" preserveAspectRatio="none">
                  <path
                    className="shape-overlays__path"
                    d="M 0 100 C 50 100 50 100 100 100 V 100 H 0"
                  ></path>
                  <path
                    className="shape-overlays__path"
                    d="M 0 100 C 50 100 50 100 100 100 V 100 H 0"
                  ></path>
                  <path
                    className="shape-overlays__path"
                    d="M 0 100 C 50 100 50 100 100 100 V 100 H 0"
                  ></path>
                </svg>
              </div>
            </div>
      
            <div className="mainmenu d-xl-block d-lg-block d-md-block d-sm-none d-none">
              <ul className="main_nav_ul">
                <li className="has-mega gc_main_navigation">
                <Link to='Namkeen'>     Home</Link>
              
                </li>
             
                <li className="has-mega gc_main_navigation">
                  <a href="#" className="gc_main_navigation">
                    Products
                  </a>
                  <ul>
                    <li className="parent">
                     <Link to='Products'>Namkeen</Link>
                    </li>
                    <li className="parent">
                    <Link to='SweetsCategory'>Sweets</Link>
                    </li>
                  </ul>
                </li>
           
           
  
                <li className="has-mega gc_main_navigation">
                <Link to='/jainshreecontact'>     Contact Us</Link>
               
               
                </li>
                {/* <Link to='/cart'>       <li className="has-mega gc_main_navigation  ">
                  <a href="#" className="gc_main_navigation">
                      <BsCart3 />
                  </a>
                        <p className='counterred' >{cart.length}</p> 
                  <ul className='buzz_cart_dropdowns' >
                  <li>
												<div class="cc_cart_wrapper1 menu-button float_left">
													<div class="cc_cart_img_wrapper">
														<img src="images/ft2.jpg" alt="cart_img"/>
													</div>
													<div class="cc_cart_cont_wrapper">
														<h4><a href="#">product name</a></h4>
														<p>Quantity : 2 × $45</p>
														<h5>$90</h5>
														<button type="button" class="close" data-dismiss="modal">×</button>
													</div>
												</div>
											</li>
                  </ul>
                </li> </Link> */}
              </ul>
            </div>
            <div className='cart-bar'>
            <div className='d-flex alie align-items-center' style={{gap:'25px', position:'relative'}}>
            <Link to='/cart'>       <li className="has-mega gc_main_navigation  ">
                  <a href="#" className="gc_main_navigation">
                      <BsCart3  size={20}/>
                  </a>
             
                        <p className='counterred' >{cart.length}</p> 
                  <ul className='buzz_cart_dropdowns' >
                  <li>
                  {cart.map((index , item)=>(
												<div  key={index} class="cc_cart_wrapper1 menu-button float_left">
													<div class="cc_cart_img_wrapper">
                          <img
                                src={item.image}
                                alt={item.title}
                              />
													</div>
                        
		<div class="cc_cart_cont_wrapper">
    <h4 color='black'>{item.title}</h4>
    <p>Quantity : 2 × $45</p>
    <h5>$90</h5>
    <button type="button" class="close" data-dismiss="modal">×</button>
  </div>
                        
											
												</div>
                          ))}
											</li>
                  </ul>
                </li> </Link>
                <div className="main main--demo-3">
      <div className="content content--demo-3">
        
        <div className="hamburger" ref={hamburgerRef}>
        <div className="hamburger__line hamburger__line--01">
                    <div className="hamburger__line-in hamburger__line-in--01"></div>
                  </div>
                  <div className="hamburger__line hamburger__line--02">
                    <div className="hamburger__line-in hamburger__line-in--02"></div>
                  </div>
                  <div className="hamburger__line hamburger__line--03">
                    <div className="hamburger__line-in hamburger__line-in--03"></div>
                  </div>
        </div>
        </div>
        <svg
          className="shape-overlays"
          ref={overlayRef}
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path className="shape-overlays__path"></path>
          <path className="shape-overlays__path"></path>
          <path className="shape-overlays__path"></path>
        </svg>
     
    </div>
                </div>
          </div>
          </div>
        </div>
        <testing/>
        {showMenu && (
        <div className="dropdown-menu-animation">
          <ul>
            <li>
            <Link onClick={()=>showMenu(false)} to='Namkeen'>     Home</Link>
            </li>
            <li>
            <Link onClick={()=>showMenu(false)}  to='Products'>Namkeen</Link>
            </li>
            <li>
            <Link onClick={()=>showMenu(false)}  to='SweetsCategory'>Sweets</Link>
            </li>
            <li>
            <Link onClick={()=>showMenu(false)}  to='/jainshreecontact'>     Contact Us</Link>
            </li>
          </ul>
        </div>
      )}
        
    </>
  );
}

export default Navbar;

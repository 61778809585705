import React from "react";
import './css/animate.css'
import './css/bootstrap.min.css'
import './css/demo.css'
// import './css/flaticon.css'
// import './css/font-awesome.css'
import './css/style.css'
import './css/style_VII.css'
import Herosec from "./Herosec";
import { useSelector } from "react-redux";

const Navigation = () => {
  const cart = useSelector((state)=>state.cart)
  return (
    <>
    <div  className="ck_navigation_main_wrapper ck_navigation_inner_main_wrapper float_left" style={{height:'570px'}}>
      <div className="container">
        
      </div>
      <Herosec/>
 
    </div>
  
    </>
  );
};

export default Navigation;

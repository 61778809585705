import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function RegisterForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  // Modified handleSubmit to prevent default behavior
  async function handleSubmit(event) {
    event.preventDefault(); // Prevent the form from submitting and reloading the page

    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    const user = {
      name,
      email,
      password,
    };

    try {
      const response = await axios.post('https://jainshree-nakin-hotel-1.onrender.com/api/user/register', user);
      console.log(response.data); // Check the response from the API
      alert('Registration successful!');
      navigate('/Loginuser'); // Navigate to home page
    } catch (error) {
      console.error('Error during registration:', error);
      alert('Registration failed, please try again.');
    }
  }

  return (
    <div className="login-container">
      <div className="wrapper">
        <form onSubmit={handleSubmit}>
          <h1>Register</h1>

          <div className="input-box">
            <input
              type="text"
              placeholder="Username"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <i className='bx bxs-user'></i>
          </div>

          <div className="input-box">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <i className='bx bxs-envelope'></i>
          </div>

          <div className="input-box">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i className='bx bxs-lock-alt'></i>
          </div>

          <div className="input-box">
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <i className='bx bxs-lock-alt'></i>
          </div>

          <div className="remember-forget">
            <label>
              <input type="checkbox" />
              Remember me
            </label>
          </div>

          <button type="submit" className="btn">
            Register
          </button>

          <div className="register-link">
            <p>
              Already have an account? <br />
              <a href="#">Login</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterForm;

import React from 'react';
import image1 from './img/gellary/thumb.jpg'
import image2 from './img/gellary/thumb-1.jpg'
import image3 from './img/gellary/thumb-2.jpg'
import image4 from './img/gellary/thumb-3.jpg'
import image5 from './img/gellary/thumb-4.jpg'
import Roomabout from './Roomabout';
function roomdetailsone() {
   
  return (
  <>
  <div class="tp-room-details-gellary mt-50 pb-85">
            <div class="p-10" style={{paddingLeft:'20px', paddingRight:'20px'}}>
               <div class="row tp-gx-15">
                  <div class="col-lg-6">
                     <div class="tp-room-gellary-thumb mb-15 p-relative">
                        <img class="w-100" src={image1} alt="thumb"/>
                        <div class="tp-room-gellary-btn">
                           <a class="tp-btn-white popup-image" href="assets/img/gellary/thumb.jpg">View Photos</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="row tp-gx-15">
                        <div class="col-lg-6 col-md-6">
                           <div class="tp-room-gellary-thumb mb-15">
                              <a class="popup-image" href="assets/img/gellary/thumb-1.jpg">
                                 <img class="w-100"  src={image2}  alt="thumb"/>
                              </a>
                           </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                           <div class="tp-room-gellary-thumb mb-15">
                              <a class="popup-image" href="assets/img/gellary/thumb-2.jpg">
                                 <img class="w-100"  src={image3}  alt="thumb"/>
                              </a>
                           </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                           <div class="tp-room-gellary-thumb mb-15">
                              <a class="popup-image" href="assets/img/gellary/thumb-3.jpg">
                                 <img class="w-100"  src={image4} alt="thumb"/>
                              </a>
                           </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                           <div class="tp-room-gellary-thumb mb-15">
                              <a class="popup-image" href="assets/img/gellary/thumb-4.jpg">
                                 <img class="w-100"  src={image5}  alt="thumb"/>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Roomabout/>
  </>
  );
}

export default roomdetailsone;

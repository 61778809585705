import React from 'react';
import boondiSpicy from './jainnamkin/jain shree products edited/boondi-spicy.png';
import chakli from './jainnamkin/jain shree products edited/chakli.png';
import chanaDaalSpicy from './jainnamkin/jain shree products edited/chana daal- spicy.png';
import chanaDaalPudhina from './jainnamkin/jain shree products edited/chana daal- pudina flavor.png';
import cheewda from './jainnamkin/jain shree products edited/cheewda.png';
import chipsBlackPepper from './jainnamkin/jain shree products edited/chips- black pepper.png';
import daalMoth from './jainnamkin/jain shree products edited/daal moth.png';
import danthalSev from './jainnamkin/jain shree products edited/danthal sev.png';
import fariyaliMixtureSpicy from './jainnamkin/jain shree products edited/fariyali mixture- spicy (2).png';
import fariyaliMixtureSweet from './jainnamkin/jain shree products edited/fariyali mixture- sweet.png';

const ProductPage = () => {
  return (
    <div className="ck_title_main_wrapper float_left">
      <div className="container">
        <div className="row">
          {/* Title and Breadcrumbs */}
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="ck_title_cont float_left">
              <p>Nakmeens</p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="ck_title_cont float_left">
              <ul>
              
                <li>Nakmeens</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Filter Sidebar and Products */}
      <div className="btc_shop_indx_main_wrapper">
        <div className="container">
          <div className="row">
            <Sidebar />
            <ProductList />
          </div>
        </div>
      </div>
    </div>
  );
};

const Sidebar = () => (
  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 d-none d-sm-none d-md-none d-lg-block d-xl-block">
    {/* Search */}
    <div className="jp_rightside_job_categories_wrapper jp_blog_right_box_search">
      <div className="jp_rightside_job_categories_heading">
        <h4>Search</h4>
      </div>
      <div className="jp_blog_right_search_wrapper">
        <input type="text" placeholder="Search" />
        <button type="submit"><i className="fa fa-search"></i></button>
      </div>
    </div>
    {/* Categories, Price, and Brand filters */}
    <FilterAccordion title="Categories" items={["National Ice Cream Month", "Baked Alaska", "Bambino (ice-cream)", "Bob ice cream bar", "Creme de papaya", "Ice cream barge"]} />
   
    {/* Ad Sidebar */}
  
  </div>
);

const FilterAccordion = ({ title, items }) => (
  <div className="cc_pc_first_accordion_wrapper cc_pc_second_accordion_wrapper">
    <div className="cc_pc_accordion">
      <ul className="accordion">
        <li className="default open">
          <div className="link cc_product_heading">{title}<i className="fa fa-chevron-down"></i></div>
          <ul className="submenu">
            <li>
              <div className="content">
                <div className="box">
                  {items.map((item, index) => (
                    <p key={index}>
                      <input type="checkbox" id={`cb${index}`} name="cb" />
                      <label htmlFor={`cb${index}`}>{item}</label>
                    </p>
                  ))}
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
);

const ProductList = () => (
  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
    {/* Product Filter and Sort Options */}

    <ProductGrid />
  </div>
);

const ProductGrid = () => (
  <div className="row">
    {[
     {
      id: 1,
      title: 'Boondi Spicy',
      price: '80',
      image: boondiSpicy,
      description: 'Aenean sollicitudin, lorem quis',
    },
    {
      id: 2,
      title: 'Chakli',
      price: '120',
      image: chakli,
      description: 'Aenean sollicitudin, lorem quis',
    },
    {
      id: 3,
      title: 'Chana Daal Spicy',
      price: '100',
      image: chanaDaalSpicy,
      description: 'Aenean sollicitudin, lorem quis',
    },
    {
      id: 4,
      title: 'Chana Daal Pudina Flavor',
      price: '110',
      image: chanaDaalPudhina,
      description: 'Aenean sollicitudin, lorem quis',
    },
    {
      id: 5,
      title: 'Cheewda',
      price: '90',
      image: cheewda,
      description: 'Aenean sollicitudin, lorem quis',
    },
    {
      id: 6,
      title: 'Chips Black Pepper',
      price: '85',
      image: chipsBlackPepper,
      description: 'Aenean sollicitudin, lorem quis',
    },
    {
      id: 7,
      title: 'Daal Moth',
      price: '95',
      image: daalMoth,
      description: 'Aenean sollicitudin, lorem quis',
    },
    {
      id: 8,
      title: 'Danthal Sev',
      price: '70',
      image: danthalSev,
      description: 'Aenean sollicitudin, lorem quis',
    },
    {
      id: 9,
      title: 'Fariyali Mixture Spicy',
      price: '120',
      image: fariyaliMixtureSpicy,
      description: 'Aenean sollicitudin, lorem quis',
    },
    {
      id: 10,
      title: 'Fariyali Mixture Sweet',
      price: '115',
      image: fariyaliMixtureSweet,
      description: 'Aenean sollicitudin, lorem quis',
    },
    ].map(product => (
      <div key={product.id} className="col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="ck_elem_slide_img_wrapper ck_elem_slide_img_wrapper_inner float_left">
          <div className="ck_elem_slide_img float_left">
            <img src={product.image} alt={product.name} />
          </div>
          <div className="ck_elem_slide_img_cont ck_elem_slide_img_cont_inner float_left">
            <h3>{product.name}</h3>
            <div className="ck_elem_star ck_elem_star_ps">
              {[...Array(5)].map((_, i) => <i key={i} className="fas fa-star"></i>)}
            </div>
            <p>Aenean sollicitudin, lorem quis</p>
            <h5>{product.price}</h5>
            <div className="ck_offer_btn ck_offer_btn_inner float_left">
              <a href="#">Add to Cart</a>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default ProductPage;
